import React from 'react'
import styled from 'styled-components'

const Button = ({ text,onClick,style,disabled,...rest }) => {
  return (
    <Container
      {...rest}
      disabled={disabled}
      style={style}
      onClick={onClick}>{text}</Container>
  )
}

export default Button

const Container = styled.button`
  color:white;
  background-color:${p => p.disabled ? `gray` : `black`};
  padding:10px 20px;
  margin:10px;
  font-size:1rem;
  border-radius:40px;
  cursor:pointer;
  box-shadow:none;
  border:none;
  white-space:nowrap;
`

