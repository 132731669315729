import React from 'react'
import { Route as RRRoute } from 'react-router-dom'
import { useUser } from '../hooks/useUser'
import AccountPending from './accountPending'
import NotAuthorised from './notAuthorised'
import Spinner from './spinner'
import PleaseLogin from './pleaseLogin'

const Route = ({ children,roles,path }) => {
  const user = useUser()
  const { role,status } = user
  
  if(roles){
    if(!user.email) return <PleaseLogin/>
    if(typeof status === `undefined`) return <Spinner/>
    if(status !== `active`) return <AccountPending/>
    if(!roles.includes(role)) return <NotAuthorised/>
  }
  return (
    <RRRoute exact path={path}>{children}</RRRoute>
  )
}

export default Route 

