import React from 'react'
import { ChevronUp,ChevronDown } from 'react-feather'
import { useDispatch,useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectSortAsc,selectSortBy,setSortAsc,setSortBy } from '../redux/slices/options.slice'

const SortCarat = ({ heading,column,align }) => {
  const sortBy = useSelector(selectSortBy)
  const sortAsc = useSelector(selectSortAsc)
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(setSortBy(column))
    dispatch(setSortAsc(!sortAsc))
  }
  return (
    <TH>
      <Container onClick={handleClick} align={align}>
        <Heading>{heading}</Heading>
        {column === sortBy && sortAsc && <ChevronDown/>}
        {column === sortBy && !sortAsc && <ChevronUp/>}
      </Container>
    </TH>
  )
}

export default SortCarat 

const TH = styled.th`
  border-bottom:1px solid #f6f6f6;
  text-align:left;
`
const Container = styled.div`
  display:flex;
  cursor: pointer;
  align-items:center;
  justify-content:${p => p.align === `right` ? `flex-end` : `flex-start`};
  flex-direction:row;
  flex-wrap:nowrap;
`
const Heading = styled.div`
  flex:0;
  white-space:nowrap;
`
