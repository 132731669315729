import React from 'react'
import styled from 'styled-components'
import HeightInput from './heightInput'
import WidthInput from './widthInput'
import OptionGroup from './optionGroup'
import ThicknessOption from './thicknessOption'
import QuantityInput from './quantityInput'
import DiameterInput from './diameterInput'

const DimensionsOption = () => {
  return (
    <Container>
      <OptionGroup label="Dimensions">
        <ThicknessOption/>
        <WidthInput/>
        <HeightInput/>
        <DiameterInput/>
        <QuantityInput/>
      </OptionGroup>
    </Container>
  )
}

export default DimensionsOption 

const Container = styled.div`
  max-width:300px;
  justify-content:flex-start;
`

