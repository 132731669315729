import React from 'react'
import { X } from 'react-feather'
import { useFB } from '../hooks/useFirebase'
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { selectOrders } from '../redux/slices/orders.slice'
import { errorStyle,successStyle } from '../utils/toast.format'
import IconButton from './iconButton'

const DeleteQuote = ({ quote }) => {
  const db = useFB()
  const orders = useSelector(selectOrders)
  const order = orders.find(o => o.quoteId === quote.id)
  const handleClick = async() => {
    if(order)
      return toast(`You can not delete quotes that have been ordered`,errorStyle)
    if(window.confirm(`Möchten Sie dieses Angebot wirklich löschen??`)){
      await db.doc(`quotes/${quote.id}`).update({ status: `deleted` })
      toast(`Angebot wurde gelöscht`,successStyle)
    }
  }
  return (
    <IconButton
      Icon={X}
      hoverText="Angebot löschen"
      onClick={handleClick}/>
  )
}

export default DeleteQuote 
