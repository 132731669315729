import React from 'react'
import styled from 'styled-components'
import Button from './button'
import { useFB } from '../hooks/useFirebase'
import { useUser } from '../hooks/useUser'

const AddFaq = () => {
  const db = useFB()
  const user = useUser()
  const handleClick = () => {
    db.collection(`faqs`).doc().set({
      createdAt: new Date(),
      createdBy: user.id,
      heading: `New FAQ`,
    })
  }
  if(user.role !== `admin`) return null
  return (
    <Container>
      <Button
        text="Add FAQ"
        onClick={handleClick}/>
    </Container>
  )
}

export default AddFaq 

const Container = styled.div`
  display:flex;
`

