import React from 'react'
import styled from 'styled-components'
import { toast } from 'react-hot-toast'
import { errorStyle } from '../utils/toast.format'

const ButtonGroup = ({ value,options,onChange,editable = true,style }) => {

  const handleClick = value => {
    if(!editable) return toast(`You can not update quotes that have been ordered`,errorStyle)
    onChange(value)
  }

  return (
    <Container style={style}>
      {options.map((option,index) => {
        return (
          <Button
            key={index}
            selected={option.value === value}
            onClick={() => handleClick(option.value)}>{option.label}</Button>
        )
      })}
    </Container>
  )
}

export default ButtonGroup 

const Container = styled.div`
  margin:0 10px;
  display:flex;
  background-color:transparent;
  flex-direction:column;
  color:white;
  padding:5px;
  box-sizing:border-box;
  flex-grow:0;
`
const Button = styled.div`
  background-color:${p => p.selected ? `white` : ``};
  color:${p => p.selected ? `black` : ``};
  border-radius:50px;
  padding:5px;
  text-align:center;
  flex:0;
  box-sizing:border-box;
  cursor:pointer;
  /* :not(:last-child){
    border-bottom:1px solid black;
  } */
  /* :first-child{
    border-top-left-radius:3px;
    border-top-right-radius:3px;
  }
  :last-child{
    border-bottom-left-radius:3px;
    border-bottom-right-radius:3px;
  } */
`

