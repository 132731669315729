import React,{ useRef,useState } from 'react'
import styled from 'styled-components'
import firebase from 'firebase/app'
import 'firebase/auth'
import { toast } from 'react-hot-toast'
import { errorStyle } from '../utils/toast.format'
import { Link } from 'react-router-dom'
import { color1,color2 } from '../utils/colors'

const ForgotPassword = () => {
  const [email,setEmail] = useState(``)
  const ref = useRef()

  const handleSubmit = async() => {
    try{
      await firebase.auth().sendPasswordResetEmail(email)
      toast(`Wir haben Ihnen eine Password Reset Email geschickt`)
    }catch(err){
      const { message } = err
      if(message) toast(message,errorStyle)
    }
  }

  const handleKeyUp = e => {
    if(e.key === `Enter`) {
      e.target.blur()
      ref.current.click()
    }
  }

  return (
    <Container>
      <Vertical>
        <Box>
          <Title>Forgot Password</Title>
          <Email
            placeholder="email"
            value={email}
            onKeyUp={handleKeyUp}
            onChange={e => {setEmail(e.target.value.toLowerCase())}}/>
          <Button ref={ref} onClick={handleSubmit}>Enter</Button>
          <Line/>
          <Options>
            <Link to="/login"><Option>{`< Zurück zum Login`}</Option></Link>
            <Link to="/signup"><Option>Create account</Option></Link>
          </Options>
        </Box>
      </Vertical>
    </Container>
  )
}

export default ForgotPassword 

const Container = styled.div`
  height:80vh;
`
const Vertical = styled.div`
  flex-direction:column;
`
const Title = styled.h2`
  margin:15px 0;
  font-size:2.5rem;
  color:white;
  font-weight:400;
  text-align:center;
`
const Box = styled.div`
  padding:80px 20px 40px 20px;
  width:auto;
  min-width:360px;
  max-width:90vw;
  box-sizing:border-box;
  flex-direction:column;
  gap:20px;
  background-image: linear-gradient(45deg,${color1},${color2});
`
const Input = styled.input`
  width:80%;
  padding:10px;
  box-sizing:border-box;
  background-color:white;
  border:none;
  border-radius:40px;
`
const Email = styled(Input)`
`
const Button = styled.button`
  width:80%;
  border:none;
  color:white;
  background-color:black;
  box-sizing:border-box;
  padding:10px 0;
  cursor:pointer;
  border-radius:40px;
`
const Line = styled.div`
  margin-top:20px;
  border-bottom: 1px solid lightgray;
  width:100%;
`
const Options = styled.div`
  width:100%;
  flex-grow:1;
  flex-direction:row;
  justify-content:space-between;
`
const Option = styled.button`
  background:transparent;
  border:none;
  color:white;
  cursor:pointer;
`