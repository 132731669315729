import React,{ useRef,useState } from 'react'
import styled from 'styled-components'
import { useFB } from '../hooks/useFirebase'
import { toast } from 'react-hot-toast'
import { successStyle } from '../utils/toast.format'
import { useSelector } from 'react-redux'
import { selectQuote } from '../redux/slices/quote.slice'

const ClientReference = () => {
  const quote = useSelector(selectQuote)

  const [value,setValue] = useState(quote.clientReference || ``)

  const ref = useRef()
  const db = useFB()

  const handleSubmit = async() => {
    await db.doc(`quotes/${quote.id}`).update({ clientReference: value })
    if(value) toast(`Kundenreferenz wurde gespeichert`,successStyle)
  }
  const handleKeyUp = e => {
    if(e.key === `Enter`)
      ref.current.blur()
  }
  const handleClick = async e => {
    e.stopPropagation()
  }
  return (
    <Container>
      <Input
        ref={ref}
        placeholder="Kundenreferenz"
        className="client-reference"
        onChange={e => setValue(e.target.value)}
        onBlur={handleSubmit}
        onKeyUp={handleKeyUp}
        onClick={handleClick}
        value={value}/>
    </Container>
  )
}

export default ClientReference 

const Container = styled.div`
  display:flex;
`
const Input = styled.input`
  width:80%;
  padding:5px;
  box-sizing:border-box;
  background-color:white;
  border:thin solid gray;
  border-radius:6px;
  margin:5px;
  font-size:1rem;
`