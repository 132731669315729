import React from 'react'
import styled from 'styled-components'
import { useDispatch,useSelector } from 'react-redux'
import { selectUnitPrices } from '../redux/slices/unitPrices.slice'
import { selectProductFilter,setProductFilter } from '../redux/slices/options.slice'

const ProductFilter = () => {
  const unitPrices = useSelector(selectUnitPrices)
  const productFilter = useSelector(selectProductFilter)
  const dispatch = useDispatch()
  const rawMaterials = [...Array.from(new Set(unitPrices
    .filter(u => u.rawMaterial)
    .map(u => u.rawMaterial)
  )),`Other`]
  
  const handleClick = product => {
    if(productFilter.includes(product))
      dispatch(setProductFilter(productFilter.filter(p => p !== product)))
    else
      dispatch(setProductFilter([...productFilter,product]))
  }
  return (
    <Container>
      {rawMaterials.map((r,index) => {
        return (
          <Option 
            key={index}
            selected={productFilter.includes(r)}
            onClick={() => handleClick(r)}>{r}</Option>
        )
      })}
    </Container>
  )
}

export default ProductFilter 

const Container = styled.div`
  display:flex;
  gap:20px;
  flex-wrap:wrap;
`
const Option = styled.div`
  display:flex;
  border-radius:40px;
  color:${p => p.selected ? `white` : `black`};
  background-color:${p => p.selected ? `black` : `white`};
  padding:10px 20px;
  width:auto;
  flex-grow:0;
  font-size:1.1rem;
  white-space:nowrap;
  cursor:pointer;
`

