import React from 'react'
import { useHistory } from 'react-router-dom'
import { defaultProduct,selectQuote } from '../redux/slices/quote.slice'
import { useDispatch,useSelector } from 'react-redux'
import { addProduct } from '../redux/slices/quote.slice'
import Button from './button'

const AddProduct = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const quote = useSelector(selectQuote)

  const handleClick = async() => {
    dispatch(addProduct(defaultProduct))
    setTimeout(() => history.push(`/quotes/${quote.id}/products/${quote.products.length}`),0)
  }

  if(quote.status) return null
  return (
    <Button
      text="+ Position hinzufügen" 
      className="disappear-in-pdf"
      style={{ margin: 0 }}
      onClick={handleClick}/>
  )
}

export default AddProduct 
