import React from 'react'
import styled from 'styled-components'
import NumberInput from './numberInput'
import { useParams } from 'react-router'
import { selectQuote,updateProduct } from '../redux/slices/quote.slice'
import { useDispatch,useSelector } from 'react-redux'

const WidthInput = () => {
  const { productId } = useParams()
  const quote = useSelector(selectQuote)
  const product = quote.products[Number(productId)] || {}
  const dispatch = useDispatch()

  const handleChange = value => {
    dispatch(updateProduct({ productId,width: value }))
  }

  if(product.model === `round`) return null
  return (
    <Container>
      <NumberInput
        label="Breite"
        units="mm"
        min={150}
        value={product.width || 0}
        editable={product.editable}
        onChange={handleChange}/>
    </Container>
  )
}

export default WidthInput 

const Container = styled.div`
  display:flex;
  flex:0;
  padding:0;
`

