import React from 'react'
import styled from 'styled-components'

const ButtonGroupHorizontal = ({ value,options,onChange }) => {
  return (
    <Container>
      {options.map((option,index) => {
        const selected = value.includes(option.value)
        return (
          <Button
            key={index}
            selected={selected}
            onClick={() => onChange(option.value)}>{option.label}</Button>
        )
      })}
    </Container>
  )
}

export default ButtonGroupHorizontal 

const Container = styled.div`
  margin:0 10px;
  display:flex;
  background-color:transparent;
  color:black;
  flex-wrap:wrap;
  gap:10px;
`
const Button = styled.div`
  background-color:${p => p.selected ? `black` : ``};
  color:${p => p.selected ? `white` : ``};
  overflow:elipsis;
  border-radius:40px;
  padding:5px 10px;
  box-sizing:border-box;
  flex: 1 1 10px;
  cursor:pointer;
  /* :not(:last-child){
    border-right:1px solid black;
  } */
  /* :first-child{
    border-top-left-radius:3px;
    border-bottom-left-radius:3px;
  }
  :last-child{
    border-top-right-radius:3px;
    border-bottom-right-radius:3px;
  } */
`

