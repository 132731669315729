import React from 'react'
import { X } from 'react-feather'
import styled from 'styled-components'
import { useFB } from '../hooks/useFirebase'

const DeleteUnitPrice = ({ unitPrice }) => {
  const db = useFB()
  const handleClick = () => {
    db.doc(`unitPrices/${unitPrice.id}`).delete()
  }
  return (
    <Container>
      <StyledX onClick={handleClick}/>
    </Container>
  )
}

export default DeleteUnitPrice 

const Container = styled.div`
  display:flex;
`
const StyledX = styled(X)`
  color:gray;
  cursor: pointer;
`

