import React from 'react'
import styled from 'styled-components'
import {
  LogOut,File,Users,User,DollarSign,HelpCircle,LogIn,
} from 'react-feather'
import { useHistory } from 'react-router-dom'
import { useUser } from '../hooks/useUser'
import { lightgray } from '../utils/colors'
import BurgerMenu from './burgerMenu'
import { Link } from 'react-router-dom'

const Navigation = () => {
  const history = useHistory()
  const user = useUser()
  let options = [
    {
      route: `/quotes`,
      label: `Angebote`,
      Icon: File,
    },
    // {
    //   route: `/orders`,
    //   label: `Orders`,
    //   Icon: FileText,
    // },
    {
      route: `/faq`,
      label: `FAQs`,
      Icon: HelpCircle,
    },
    {
      route: `/users`,
      label: `Users`,
      Icon: User,
      roles: [`admin`],
    },
    {
      route: `/teams`,
      label: `Teams`,
      Icon: Users,
      roles: [`admin`],
    },
    {
      route: `/unit_prices`,
      label: `Stückpreise`,
      Icon: DollarSign,
      roles: [`admin`],
    },
  ]

  if(user.email) options.push({
    route: `/account`,
    label: `Mein Konto`,
    Icon: User,
  })

  options.push(
    user.email
      ? {
        route: `/logout`,
        label: `Abmelden`,
        Icon: LogOut,
      }
      : {
        route: `/login`,
        label: `Anmelden`,
        Icon: LogIn,
      } 
  )
  options = options.filter(option => !option.roles || option.roles.includes(user.role))
  return (
    <Container>
      <Link to="/"><Home src={`/v-black-transparent.png`}/></Link>
      {options
        .slice(0,0)
        .map((option,index) => {
          const { route,label,Icon } = option
          const { pathname } = history.location
          return (
            <Option 
              active={pathname === route || (route !== `/` && pathname.includes(route))}
              onClick={() => history.push(route)}
              key={index}>
              <Icon size={16}/>
              <Heading>{label}</Heading>
            </Option>
          )
        })}
      <BurgerMenu options={options}/>
    </Container>
  )
}

export default Navigation 

const Container = styled.div`
  /* width:100vw; */
  overflow-x:hidden;
  display:flex;
  flex:0;
  scrollbar-width: none;
  &::-webkit-scrollbar { display:none;}
  box-sizing:border-box;
  align-items:unset;
  justify-content:center;
  padding:20px;
  gap:0px;
  background-color:${lightgray};
`
const Home = styled.img`
  height:60px;
  margin: 0 10px;
  flex:1;
`
const Option = styled.div`
  gap:10px;
  justify-content:center;
  cursor:pointer;
  font-size:0.8rem;
  padding:5px 15px;
  min-width:170px;
  box-sizing:border-box;
  border-radius:40px;
  border-radius:0;
  flex:0;
  /* border-bottom:${p => p.active ? `1px solid black` : ``}; */
`
const Heading = styled.div`
  font-weight:500;
  box-sizing:border-box;
  font-size:1.1rem;
  margin:0;
  padding:0;
  flex:0;
  white-space: nowrap;
`

