import React from 'react'
import styled from 'styled-components'
import { X } from 'react-feather'
import { useFB } from '../hooks/useFirebase'

const DeleteFaq = ({ faq }) => {
  const db = useFB()
  const handleClick = () => {
    db.doc(`faqs/${faq.id}`).delete()
  }
  return (
    <Container onClick={handleClick}>
      <StyledX/> 
    </Container>
  )
}

export default DeleteFaq 

const Container = styled.div`
  margin:20px;
  display:flex;
  flex-grow:0;
  flex-shrink:1;
  width:auto;
`
const StyledX = styled(X)`
  cursor:pointer;
  color:gray;
`

