import moment from 'moment'

export const formatCurrency = value => {
  if(typeof value === `undefined`) return ``
  return Number(value)
    .toLocaleString(`gb-GB`,{ style: `currency`,currency: `EUR`,minimumFractionDigits: 2,maximumFractionDigits: 2 })
}
export const formatPercentage = value => `${(value * 100).toFixed(2)}%`
export const formatThousandsPercentage = value => `${(value * 100).toFixed(3)}%`
export const formatDate = value => {
  if(!value) return null 
  return moment(value.slice(0,10)).format(`MM/DD/YYYY`)
}
export const formatDatetime = createdAt => {
  return moment(createdAt.seconds * 1000).format(`YYYY-MM-DD HH:mm`)
}
export const formatCommas = value => Number(value || 0).toLocaleString()