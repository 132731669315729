import React from 'react'
import styled from 'styled-components'
import Button from './button'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetQuote } from '../redux/slices/quote.slice'

const CreateQuote = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleClick = async() => {
    dispatch(resetQuote())
    history.push(`/quotes/new-quote/products/0`)
  }
  return (
    <Container>
      <Button
        text="Neues Angebot erstellen"
        onClick={handleClick}/>
    </Container>
  )
}

export default CreateQuote 

const Container = styled.div`
  display:flex;
  flex-direction:column;
`
