import React from 'react'
import styled from 'styled-components'
import { useDispatch,useSelector } from 'react-redux'
import { selectUnitPrices } from '../redux/slices/unitPrices.slice'
import { selectWidthFilter,setWidthFilter } from '../redux/slices/options.slice'

const WidthFilter = () => {
  const unitPrices = useSelector(selectUnitPrices)
  const widthFilter = useSelector(selectWidthFilter)
  const dispatch = useDispatch()
  const options = Array.from(new Set(unitPrices
    .filter(u => u.width)
    .map(u => Number(u.width))
    .sort((a,b) => a - b) 
  )) 
  
  const handleClick = width => {
    if(widthFilter.includes(width))
      dispatch(setWidthFilter(widthFilter.filter(p => p !== width)))
    else
      dispatch(setWidthFilter([...widthFilter,width]))
  }
  return (
    <Container>
      {options.map((r,index) => {
        return (
          <Option 
            key={index}
            selected={widthFilter.includes(r)}
            onClick={() => handleClick(r)}>{r}</Option>
        )
      })}
    </Container>
  )
}

export default WidthFilter 

const Container = styled.div`
  display:flex;
  gap:10px;
  flex-wrap:wrap;
`
const Option = styled.div`
  display:flex;
  border-radius:40px;
  color:${p => p.selected ? `white` : `black`};
  background-color:${p => p.selected ? `black` : `white`};
  padding:5px 10px;
  font-size:1.1rem;
  width:auto;
  flex:0;
  white-space:nowrap;
  cursor:pointer;
`

