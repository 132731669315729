import React from 'react'
import './styles.css'
import Router from './components/router'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryMessage from './components/errorBoundaryMessage'
import { Toaster } from 'react-hot-toast'

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryMessage} >
      <BrowserRouter basename="/">
        <Toaster position="top-center"/>
        <Router/>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default App