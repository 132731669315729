import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectOrders } from '../redux/slices/orders.slice'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const OrdersTable = () => {
  const orders = useSelector(selectOrders)
  const history = useHistory()


  const handleClick = order => {
    history.push(`/orders/${order.id}`)
  }

  if(orders.length === 0) return <NoData>You have not yet created any orders</NoData>
  return (
    <Container>
      <Table>
        <Thead>
          <tr>
            <TH>ID</TH>
            <TH>Created By</TH>
            <TH>Created At</TH>
          </tr>
        </Thead>
        <Tbody>
          {orders.map((order,index) => {
            const { createdAt,createdBy } = order
            return (
              <tr key={index}>
                <TDL onClick={() => handleClick(order)}>{order.id}</TDL>
                <TD>{createdBy}</TD>
                <TD>{moment(createdAt.seconds * 1000).format(`YYYY-MM-DD HH:mm`)}</TD>
              </tr>
            )
          })}
        </Tbody>
      </Table>
    </Container>
  )
}

export default OrdersTable 

const Container = styled.div`
  display:flex;
  overflow-x: auto;
  max-width:100%;
  box-sizing:content-box;
  justify-content:unset;
`
export const NoData = styled.div`
  color:black;
  margin:20px;
  padding:10px;
  font-size:1.5rem;
`
const Table = styled.table`
  flex-grow:1;
`
const Thead = styled.thead`
`
const Tbody = styled.tbody`
`
const TH = styled.th`
  border-bottom:1px solid #f6f6f6;
  text-align:left;
`
const TD = styled.td`
  border-radius:0;
  border-bottom:1px solid #f6f6f6;
`
const TDL = styled(TD)`
  cursor:pointer;
`

