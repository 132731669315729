import { createAsyncThunk,createSlice } from '@reduxjs/toolkit'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { parseCollectionSnapshot } from '../../utils/firebaseUtils'
import { selectUser } from './user.slice'
import { selectTeams } from './teams.slice'

const name = `quotes`
let unsubscribe

export const subscribeToQuotes = createAsyncThunk(`${name}/subscribe`,async(_,{ getState,dispatch }) => {
  if(unsubscribe) unsubscribe() 
  const state = getState()
  const user = selectUser(state)
  const teams = selectTeams(state)
    .filter(t => t.members && t.members.includes(user.email))
  const createdByList = [user.email]
  teams.forEach(team => createdByList.push(...team.members))

  let ref = firebase.firestore().collection(name)
  if(user.role !== `admin`) ref = ref.where(`createdBy`,`in`,createdByList)
  unsubscribe = ref.onSnapshot(snapshot => {
    dispatch(setQuotes(parseCollectionSnapshot(snapshot)))
  })
},{ condition: () => !!firebase.auth().currentUser })

const slice = createSlice({
  name,
  initialState: {
    status: `idle`,
    data: [],
    error: null,
  },
  reducers: {
    setQuotes(state,action){
      state.data = action.payload
      state.status = `loaded`
    },
  },
})

export default slice.reducer
export const { setQuotes } = slice.actions
export const selectQuotes = state => state[name].data
export const selectQuotesSlice = state => state[name]

