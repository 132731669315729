import React from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import { useHistory } from 'react-router'

const PleaseLogin = () => {
  const history = useHistory()
  const onClick = () => {
    const { pathname } = history.location
    console.log({ pathname })
    history.push(`/login${pathname ? `?next=${pathname}` : ``}`)
  }
  return (
    <MainLayout>
      <Container>
        <Title>Bitte Loggen Sie Sich ein</Title>
        <Link onClick={onClick}>Zum Login</Link>
      </Container>
    </MainLayout>
  )
}

export default PleaseLogin 

const Container = styled.div`
  display:flex;
  flex-direction:column;
  gap:20px;
`
const Title = styled.h2`
`
const Link = styled.div`
  color:white;
  background-color:black;
  border-radius:40px;
  cursor:pointer;
  flex:0;
  width:auto;
  padding:10px 20px;
`

