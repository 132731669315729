import React from 'react'
import styled from 'styled-components'
import { color1,color2 } from '../utils/colors'

const Spinner = () => {
  return (
    <Container>
      <SpinnerImage className="spinner"/>
    </Container>
  )
}

export default Spinner 

const Container = styled.div`
  display:flex;

  .spinner {
    margin:20px;
    border: 8px solid ${color2}; 
    border-top: 8px solid ${color1}; 
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite ;
    animation-fill-mode:both;
  }

  @keyframes spin {
    0% { 
      transform: rotate(0deg); 
      /* width:150px; */
      /* height:150px; */
    }
    50% { 
      transform: rotate(180deg); 
      /* width:110px; */
      /* height:110px; */
    }
    100% { 
      transform: rotate(360deg); 
      /* width:150px; */
      /* height:150px; */
    }
  }
`
const SpinnerImage = styled.div`
  flex-grow:0;
`

