import { createSlice } from '@reduxjs/toolkit'

const name = `options`

const slice = createSlice({
  name,
  initialState: { 
    selectedDomains: [],
    productFilter: [],
    thicknessFilter: [],
    heightFilter: [],
    widthFilter: [],
    page: 0,
    perPage: 15,
    sortBy: `createdAt`,
    sortAsc: false,
    searchTerm: ``,
    userSearchTerm: ``,
    userSortBy: `email`,
    userSortAsc: true,
    unitPriceDomain: ``,
  },
  reducers: {
    setSelectedDomains(state,action){
      state.selectedDomains = action.payload
    },
    setProductFilter(state,action){
      state.productFilter = action.payload
    },
    setThicknessFilter(state,action){
      state.thicknessFilter = action.payload
    },
    setHeightFilter(state,action){
      state.heightFilter = action.payload
    },
    setWidthFilter(state,action){
      state.widthFilter = action.payload
    },
    setPage(state,action){
      state.page = Number(action.payload)
    },
    setSortBy(state,action){
      state.sortBy = action.payload
    },
    setSortAsc(state,action){
      state.sortAsc = !!action.payload
    },
    setUserSortBy(state,action){
      state.userSortBy = action.payload
    },
    setUserSortAsc(state,action){
      state.userSortAsc = !!action.payload
    },
    setSearchTerm(state,action){
      state.searchTerm = action.payload
    },
    setUserSearchTerm(state,action){
      state.userSearchTerm = action.payload
    },
    setUnitPriceDomain(state,action){
      state.unitPriceDomain = action.payload
    },
  },
})

export default slice.reducer
export const {
  setPage,setSelectedDomains,setProductFilter,setThicknessFilter,setUserSearchTerm,
  setHeightFilter,setWidthFilter,setSortAsc,setSortBy,setSearchTerm,
  setUserSortAsc,setUserSortBy,setUnitPriceDomain,
} = slice.actions
export const selectSelectedDomains = state => state[name].selectedDomains
export const selectProductFilter = state => state[name].productFilter
export const selectThicknessFilter = state => state[name].thicknessFilter
export const selectHeightFilter = state => state[name].heightFilter
export const selectWidthFilter = state => state[name].widthFilter
export const selectPage = state => state[name].page
export const selectPerPage = state => state[name].perPage
export const selectSortBy = state => state[name].sortBy
export const selectSortAsc = state => state[name].sortAsc
export const selectUserSortBy = state => state[name].userSortBy
export const selectUserSortAsc = state => state[name].userSortAsc
export const selectSearchTerm = state => state[name].searchTerm
export const selectUserSearchTerm = state => state[name].userSearchTerm
export const selectUnitPriceDomain = state => state[name].unitPriceDomain


