import React from 'react'
import styled from 'styled-components'
import MirrorOptions from './mirrorOptions'
import HangingTypeSelector from './hangingTypeSelector'
import HoleOptions from './holeOptions'
import TaperedOption from './taperedOption'
import ModelOption from './modelOption'
import DimensionsOption from './dimensionsOption'

const Configuration = () => {
  return (
    <Container>
      <MirrorOptions/>
      <DimensionsOption/>
      <ModelOption/>
      <TaperedOption/>
      <HangingTypeSelector/>
      <HoleOptions/>
    </Container>
  )
}

export default Configuration 

const Container = styled.div`
  align-items:stretch;
  gap:20px 20px;
  flex-wrap:wrap;
  flex-grow:0;
`

