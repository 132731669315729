import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectUnitPrices } from '../redux/slices/unitPrices.slice'
import { getLineItems } from '../utils/getLineItems'
import { formatCurrency } from '../utils/formatter'
import { selectQuote } from '../redux/slices/quote.slice'
import { useParams } from 'react-router'

const LineItems = () => {
  const { productId } = useParams()
  const quote = useSelector(selectQuote)
  const product = quote.products[Number(productId)] || {}
  const unitPrices = useSelector(selectUnitPrices)
  const lineItems = getLineItems(product,unitPrices)
  const total = lineItems.reduce((acc,cur) => {
    return acc += cur.unitPrice.price * cur.quantity
  },0)

  if(!lineItems || !lineItems.length) return null
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <TH>Produktnummer</TH>
            <TH>Beschreibung</TH>
            <THR>Stückpreis</THR>
            <THR>Preis</THR>
          </tr>
        </thead>
        <tbody>
          {lineItems.map((lineItem,index) => {
            const { unitPrice,quantity } = lineItem
            return (
              <tr key={index}>
                <TD>{unitPrice.productNumber}</TD>
                <TD>{unitPrice.description}</TD>
                <TDR>{formatCurrency(unitPrice.price)}</TDR>
                <TDR>{formatCurrency(unitPrice.price * quantity)}</TDR>
              </tr>
            )
          })}
          <tr>
            <td></td>
            <td></td>
            <TDR></TDR>
            <TotalCell>{formatCurrency(total)}</TotalCell>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <TDR>Anzahl Spiegel:</TDR>
            <TDR>{product.quantity}</TDR>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <TDR>Total:</TDR>
            <TDR>{formatCurrency(total * product.quantity)}</TDR>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}

export default LineItems 

const Container = styled.div`
  display:flex;
  box-sizing:border-box;
  overflow:scroll;
  @media (max-width: 600px) {
    justify-content:unset;
    align-items:unset;
  }
`
const TH = styled.th`
  text-align:left;
`
const THR = styled(TH)`
  text-align:right;
`
const Table = styled.table`
  flex-grow:1;
`
const TD = styled.td`
  text-align:left;
`
const TDR = styled(TD)`
  text-align:right;
`
const TotalCell = styled.td`
  border-top:1px solid black;
  text-align:right;
`

