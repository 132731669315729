import React,{ useState } from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import { useSelector } from 'react-redux'
import { selectFaqs } from '../redux/slices/faqs.slice'
import Faq from './faq'
import AddFaq from './addFaq'
import ReactToggle from 'react-toggle'
import 'react-toggle/style.css'
import { useUser } from '../hooks/useUser'

const Faqs = () => {
  const faqs = useSelector(selectFaqs)
  const [edit,setEdit] = useState(false)
  const user = useUser()
  const admin = user.role === `admin`

  return (
    <MainLayout>
      <Container>
        {admin && 
          <ToggleContainer>
            <Label>Edit</Label>
            <ReactToggle
              style={{ flexGrow: 0 }}
              checked={edit}
              onChange={() => setEdit(!edit)}/> 
          </ToggleContainer>
        }
        <Title>Frequently Asked Questions</Title>
        {faqs.map((faq,index) => {
          return (
            <Faq 
              key={index} 
              preview={!edit}
              faq={faq}/>
          )
        })}
        {admin && edit && <AddFaq/>}
      </Container>
    </MainLayout>
  )
}

export default Faqs

const Container = styled.div`
  flex-direction:column;
  gap:10px;
`
const ToggleContainer = styled.div`
  margin:10px;
`
const Label = styled.h2`
  margin:10px;
`
const Title = styled.h1`
  text-align:center;
`

