import React from 'react'
import { X } from 'react-feather'
import styled from 'styled-components'
import { useFB } from '../hooks/useFirebase'
import { FieldValue } from '../utils/firebaseUtils'

const Member = ({ team,member }) => {
  const fb = useFB()
  const handleClick = () => {
    fb.doc(`teams/${team.id}`)
      .update({ members: FieldValue.arrayRemove(member) })
  }
  return (
    <Container>
      {member} 
      <StyledX className="delete" onClick={handleClick}/>
    </Container>
  )
}

export default Member 

const Container = styled.div`
  display:flex;
  background-color:transparent;
  flex-grow:0;
  width:auto;
  &:hover>.delete{
    opacity:1;
  }
`
const StyledX = styled(X)`
  cursor: pointer;
  opacity:0;
  transition: opacity 0.3s ease-in-out;
`

