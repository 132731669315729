import React from 'react'
import { Copy } from 'react-feather'
import { useDispatch } from 'react-redux'
import { addProduct } from '../redux/slices/quote.slice'
import IconButton from './iconButton'

const DuplicateProduct = ({ product }) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(addProduct(product))
  }
  return (
    <IconButton
      Icon={Copy}
      hoverText="Position duplizieren"
      onClick={handleClick}/>
  )
}

export default DuplicateProduct 
