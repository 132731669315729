import React from 'react'
import { ChevronUp,ChevronDown } from 'react-feather'
import { useDispatch,useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectUserSortAsc,selectUserSortBy,setUserSortAsc,setUserSortBy } from '../redux/slices/options.slice'

const UserSortCarat = ({ heading,column }) => {
  const sortBy = useSelector(selectUserSortBy)
  const sortAsc = useSelector(selectUserSortAsc)
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(setUserSortBy(column))
    dispatch(setUserSortAsc(!sortAsc))
  }
  return (
    <TH>
      <Container onClick={handleClick}>
        <Heading>{heading}</Heading>
        {column === sortBy && sortAsc && <ChevronDown/>}
        {column === sortBy && !sortAsc && <ChevronUp/>}
      </Container>
    </TH>
  )
}

export default UserSortCarat 

const TH = styled.th`
  border-bottom:1px solid #f6f6f6;
  text-align:left;
`
const Container = styled.div`
  display:flex;
  cursor: pointer;
  align-items:center;
  justify-content:center;
  flex-direction:row;
  flex-wrap:nowrap;
`
const Heading = styled.div`
  flex:0;
  white-space:nowrap;
`
