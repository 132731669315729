import React from 'react'
import styled from 'styled-components'
import { useDispatch,useSelector } from 'react-redux'
import { selectUnitPrices } from '../redux/slices/unitPrices.slice'
import { selectHeightFilter,setHeightFilter } from '../redux/slices/options.slice'

const HeightFilter = () => {
  const unitPrices = useSelector(selectUnitPrices)
  const heightFilter = useSelector(selectHeightFilter)
  const dispatch = useDispatch()
  const options = Array.from(new Set(unitPrices
    .filter(u => u.height)
    .map(u => Number(u.height))
    .sort((a,b) => a - b) 
  ))

  const handleClick = height => {
    if(heightFilter.includes(height))
      dispatch(setHeightFilter(heightFilter.filter(p => p !== height)))
    else
      dispatch(setHeightFilter([...heightFilter,height]))
  }
  return (
    <Container>
      {options.map((r,index) => {
        return (
          <Option 
            key={index}
            selected={heightFilter.includes(r)}
            onClick={() => handleClick(r)}>{r}</Option>
        )
      })}
    </Container>
  )
}

export default HeightFilter 

const Container = styled.div`
  display:flex;
  gap:10px;
  flex-wrap:wrap;
`
const Option = styled.div`
  display:flex;
  border-radius:40px;
  color:${p => p.selected ? `white` : `black`};
  background-color:${p => p.selected ? `black` : `white`};
  padding:5px 10px;
  font-size:1.1rem;
  width:auto;
  flex:0;
  white-space:nowrap;
  cursor:pointer;
`

