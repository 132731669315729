import React from 'react'
import styled from 'styled-components'
import AddTeam from './addTeam'
import TeamsTable from './teamsTable'
import MainLayout from './mainLayout'

const Teams = () => {
  return (
    <MainLayout>
      <Container>
        <TeamsTable/>
        <AddTeam/>
      </Container>
    </MainLayout>
  )
}

export default Teams 

const Container = styled.div`
  display:flex;
  flex-direction:column;
`