import React,{ useEffect,useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import { highlight } from '../utils/colors'
import { errorStyle } from '../utils/toast.format'

const NumberInput = ({ 
  label,
  value,
  onChange,
  units,
  min,
  editable = true,
}) => {

  const [currentValue,setCurrentValue] = useState(Number(value))

  useEffect(() => setCurrentValue(Number(value)),[value])

  const handleSubmit = () => {
    if(currentValue < min){
      setCurrentValue(min)
      onChange(min)
      toast(`Der Mindestwert ist ${min}`,errorStyle)
    }else
      onChange(Number(currentValue))
  }

  const handleKeyUp = e => {
    if(e.key === `Enter`) e.target.blur()
  }

  return (
    <Container>
      <Label>{label}:</Label>
      {editable && 
      <Input 
        value={Number(currentValue)}
        onChange={e => setCurrentValue(Number(e.target.value.replace(/[^0-9]/g,``))) }
        onBlur={handleSubmit}
        onKeyUp={handleKeyUp}
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"/>
      }
      {!editable && 
        <Label>{value}</Label>
      }
      <Label>{units}</Label>
    </Container>
  )
}

export default NumberInput 

const Container = styled.div`
  display:flex;
  padding:5px 15px;
  width:auto;
  flex-grow:1;
  color:white;
  gap:10px;

`
export const Label = styled.h2`
  font-weight:400;
  font-size:1rem;
  margin:0;
`
const Input = styled.input`
  text-align:right;
  max-width:60px;
  border-radius:40px;
  padding: 5px 10px;
  box-shadow:none;
  &:focus {
    outline:none;
    box-shadow: 0 0 3pt 2pt ${highlight};
  }
`

