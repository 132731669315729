import React from 'react'
import styled from 'styled-components'

const OptionGroup = ({ label,children }) => {
  return (
    <Container>
      <Label>{label}</Label>
      {children}
    </Container>
  )
}

export default OptionGroup 

const Container = styled.div`
  flex-direction:column;
  background-color:black;
  box-sizing:border-box;
  padding:40px;
  height:100%;
  flex-grow:0;
  justify-content:flex-start;
`
const Label = styled.div`
  padding:10px;
  color:white;
  text-align:center;
  font-size:2rem;
  flex-grow:0;
`