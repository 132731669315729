import styled from 'styled-components'
import ButtonGroup from './buttonGroup'
import { selectHangingTypeOptions } from '../redux/selectors/hangingTypeOptions.selector'
import OptionGroup from './optionGroup'
import React from 'react'
import { useParams } from 'react-router'
import { selectQuote,updateProduct } from '../redux/slices/quote.slice'
import { useDispatch,useSelector } from 'react-redux'

const HangingTypeSelector = () => {
  const { productId } = useParams()
  const quote = useSelector(selectQuote)
  const product = quote.products[Number(productId)] || {}
  const dispatch = useDispatch()

  const handleChange = value => {
    dispatch(updateProduct({ productId,hangingType: value }))
  }

  const hangingTypeOptions = useSelector(selectHangingTypeOptions)
  const optionise = o => ({ value: o,label: o })
  const options = hangingTypeOptions.map(optionise)
  
  return (
    <Container>
      <OptionGroup label="Aufhängung">
        <ButtonGroup
          onChange={handleChange}
          value={product.hangingType}
          editable={product.editable}
          options={[{ value: false,label: `Keine` },...options]}/>
      </OptionGroup>
    </Container>
  )
}

export default HangingTypeSelector 

const Container = styled.div`
  max-width:300px;
`

