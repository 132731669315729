import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import { useFB } from '../hooks/useFirebase'
import { useUser } from '../hooks/useUser'
import { toast } from 'react-hot-toast'
import { errorStyle,successStyle } from '../utils/toast.format'
import firebase from 'firebase/app'

const Price = ({ unitPrice,price }) => {
  const db = useFB()
  const user = useUser()
  const [value,setValue] = useState(unitPrice[price])

  useEffect(() => setValue(unitPrice[price]),[unitPrice,price])

  const handleSubmit = async() => {
    if(Number(unitPrice[price]) === Number(value)) return 
    if(user.role !== `admin`)
      return toast(`Nur Admins können Preise verändern`,errorStyle)
    const field = new firebase.firestore.FieldPath(price)
    await db.doc(`unitPrices/${unitPrice.id}`).update(field,Number(value).toFixed(2))
    toast(`Preisänderung wurde gespeichert`,successStyle)
  }

  const handleKeyUp = e => {
    if(e.key === `Enter`) {
      e.target.blur()
      handleSubmit()
    }
  }

  return (
    <Input
      value={value}
      onChange={e => {
        let { value } = e.target
        value = value.replace(/[^0-9.]/,``)
        setValue(value)
      }}
      onBlur={handleSubmit}
      onKeyUp={handleKeyUp}/>
  )
}

export default Price 
const Input = styled.input`
  border-radius:40px;
  text-align:right;
`
