import React,{ useState } from 'react'
import styled from 'styled-components'
import { useUser } from '../hooks/useUser'
import ReactMarkdown from 'react-markdown'
import DeleteFaq from './deleteFaq'
import MarkdownEditor from './markdownEditor'
import { useFB } from '../hooks/useFirebase'


const Faq = ({ faq,preview }) => {
  const user = useUser()
  const admin = user.role === `admin`
  const db = useFB()

  const [value,setValue] = useState(faq.markdown || `# New FAQ`)

  const handleBlur = () => {
    db.doc(`faqs/${faq.id}`).update({ markdown: value })
  }

  return (
    <Container>
      {admin && !preview && 
      <MarkdownEditor
        markdown={value} 
        onBlur={handleBlur}
        onChange={e => {
          console.log(e)
          setValue(e)
        }}/>
      }    
      <Vertical>
        <ReactMarkdown>{value}</ReactMarkdown>
      </Vertical>
      {admin && !preview && <DeleteFaq faq={faq}/>}
    </Container>
  )
}

export default Faq 

const Container = styled.div`
  margin-bottom:30px;
  h1,h2,h3,h4{
    margin-bottom:5px;
  }
  p{
    margin:5px 0;
  }

  /* border-bottom:1px solid gray; */
  border-radius:0;
`
const Vertical = styled.div`
  align-self:flex-start;
  flex-direction:column;
  justify-content:flex-start;
  align-items:flex-start;
  margin:0 30px;
`

