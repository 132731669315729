import React,{ Suspense } from 'react'
import styled from 'styled-components'
import Spinner from './spinner'
import { Switch } from 'react-router-dom'
import Route from './route'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { useDispatch } from 'react-redux'
import { subscribeToUser } from '../redux/slices/user.slice'
import Landing from './landing'
import Login from './login'
import SignUp from './signup'
import Logout from './logout'
import ForgotPassword from './forgotPassword'
import NotAuthorised from './notAuthorised'
import Quotes from './quotes'
import Quote from './quote'
import Teams from './teams'
import Users from './users'
// import Orders from './orders'
// import Order from './order'
import UnitPrices from './unitPrices'
import Faqs from './faqs'
import { subscribeToFaqs } from '../redux/slices/faqs.slice'
import Account from './account'

const Router = () => {
  const dispatch = useDispatch()
  dispatch(subscribeToFaqs())
  firebase.auth().onAuthStateChanged(user => {
    if(user){
      const { displayName,email,uid } = user
      const userObject = { displayName,email,id: email,uid }
      userObject.displayName = displayName || email
      userObject.lastLogin = new Date()
      firebase.firestore().doc(`/users/${email}`).set(userObject,{ merge: true })
      dispatch(subscribeToUser(email))
    }
  })
  return (
    <Container>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/login"><Login/></Route>
          <Route path="/spinner"><Spinner/></Route>
          <Route path="/signup"><SignUp/></Route>
          <Route path="/forgot_password"><ForgotPassword/></Route>
          <Route path="/logout"><Logout/></Route>
          <Route path="/not_authorised"><NotAuthorised/></Route>
          <Route path="/new-quote" roles={[`quoter`,`orderer`,`admin`]}><Quote/></Route>
          <Route path="/quotes/:quoteId/products/:productId" roles={[`quoter`,`orderer`,`admin`]}><Quote/></Route>
          <Route path="/quotes/:quoteId" roles={[`quoter`,`orderer`,`admin`]}><Quote/></Route>
          <Route path="/quotes" roles={[`quoter`,`orderer`,`admin`]}><Quotes/></Route>
          {/* <Route path="/orders/:orderId" roles={[`quoter`,`orderer`,`admin`]}><Order/></Route> */}
          {/* <Route path="/orders" roles={[`quoter`,`orderer`,`admin`]}><Orders/></Route> */}
          <Route path="/teams" roles={[`admin`]}><Teams/></Route>
          <Route path="/users" roles={[`admin`]}><Users/></Route>
          <Route path="/account" roles={[`quoter`,`orderer`,`admin`]}><Account/></Route>
          <Route path="/unit_prices" roles={[`admin`]}><UnitPrices/></Route>
          <Route path="/faq"><Faqs/></Route>
          <Route path="/"><Landing/></Route>
        </Switch>
      </Suspense>
    </Container>
  )
}

export default Router 

export const upsertUserInFirestore = newUser => {
  const user = { ...newUser }
  if(!user.displayName) user.displayName = user.email
  const { displayName,email,uid } = user
  return firebase.firestore().doc(`/users/${email}`)
    .set({ displayName,email,id: email,uid },{ merge: true })
}

const Container = styled.div`
  display:flex;
`

