import React from 'react'
import { Edit } from 'react-feather'
import { useDispatch } from 'react-redux'
import { errorStyle } from '../utils/toast.format'
import { toast } from 'react-hot-toast'
import IconButton from './iconButton'
import { useHistory } from 'react-router-dom'
import { setQuote } from '../redux/slices/quote.slice'
import Button from './button'

const DuplicateQuote = ({ quote,large }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const handleClick = async() => {
    try{
      const { id,path,status,...newQuote } = quote
      dispatch(setQuote(newQuote))
      history.push(`/quotes/new-quote`)
    }catch(err){
      console.log(err)
      toast(`Sorry, wir konnten kein neues Angebot erstellen. Bitte versuchen Sie es erneut.`,errorStyle)
    }
  }

  return (
    <>
      {!large && <IconButton
        Icon={Edit}
        hoverText="Angebot bearbeiten"
        onClick={handleClick}/>}
      {large && <Button
        text="Angebot bearbeiten"
        onClick={handleClick}/>}
    </>
  )
}

export default DuplicateQuote 