import { all,put,takeEvery } from 'redux-saga/effects'
import { subscribeToUsers } from '../slices/users.slice'
import { subscribeToTeams } from '../slices/teams.slice'
import { subscribeToQuotes } from '../slices/quotes.slice'
import { subscribeToOrders } from '../slices/orders.slice'
import { subscribeToUnitPrices } from '../slices/unitPrices.slice'
import { subscribeToFaqs } from '../slices/faqs.slice'

function* listenToUserChanges(){
  yield takeEvery(`user/setUser`,function* runStepsUpdates(){
    yield put(subscribeToTeams())
    yield put(subscribeToQuotes())
    yield put(subscribeToUnitPrices())
    yield put(subscribeToFaqs())
    yield put(subscribeToUsers())
    yield put(subscribeToOrders())
  })
}

function* listenToTeamsChanges(){
  yield takeEvery(`teams/setTeams`,function* runTeamsUpdates(){
    yield put(subscribeToQuotes())
  })
}

export default function* userSaga() {
  yield all([
    listenToUserChanges(),
    listenToTeamsChanges(),
  ])
}