import React,{ useContext } from 'react'
import Modal from './modal'
import { QuoteContext } from './quote'
import styled from 'styled-components'
import DeleteProduct from './deleteProduct'
import DuplicateProduct from './duplicateProduct'
import { getLineItems } from '../utils/getLineItems'
import { useSelector } from 'react-redux'
import { formatCurrency } from '../utils/formatter'
import { selectUnitPrices } from '../redux/slices/unitPrices.slice'
import Configuration from './configuration'
import LineItems from './lineItems'
import Button from './button'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { infoStyle } from '../utils/toast.format'

const Product = ({ index,product }) => {
  const history = useHistory()
  const unitPrices = useSelector(selectUnitPrices)
  const quote = useContext(QuoteContext)
  const { productId } = useParams()
  let { 
    tapered,model,rawMaterial,holeType,hangingType,width,height,thickness,
    quantity, 
  } = product
  const lineItems = getLineItems(product,unitPrices)

  const unitPrice = lineItems.reduce((acc,cur) => {
    return acc += Number(cur.unitPrice.price) * Number(cur.quantity)
  },0)


  const details = [`${rawMaterial ? rawMaterial : ``} ${width}mm x ${height} mm x ${thickness}mm`]
  if(hangingType) details.push(hangingType)
  if(holeType) details.push(`Löcher ${holeType}`)
  if(tapered) details.push(`Facettenschliff`)
  if(model) details.push({
    round: `Rund`,
    other: `Andere Form`,
  }[model])

  const openConfigurator = () => {
    if(quote.status) return toast(`Gespeicherte Angebote können nicht mehr bearbeitet werden`,infoStyle)
    history.push(`/quotes/${quote.id}/products/${index}`)
  }
  const closeConfigurator = () => {
    history.push(`/quotes/${quote.id}`)
  }

  return (
    <TR key={index} >
      <TD onClick={openConfigurator}>{`${index + 1}`}</TD>
      <TD onClick={openConfigurator}>{details.filter(Boolean).join(` - `)}</TD>
      <TDR>{quantity}</TDR>
      <TDR>{formatCurrency(unitPrice)}</TDR>
      <TDR>{formatCurrency(Number(unitPrice || 0) * Number(quantity || 0))}</TDR>
      {!quote.status && <TD><DuplicateProduct product={product}/></TD>}
      {!quote.status && <TD><DeleteProduct product={product} index={index}/></TD>}
      <Modal show={index === Number(productId)} close={closeConfigurator}>
        <ProductContainer>
          <H1>Spiegel Konfiguration</H1>
          <Configuration/>
          <LineItems/>
          <Button text="Bestätigen" onClick={() => history.push(`/quotes/${quote.id}`)}/>
        </ProductContainer>
      </Modal>
    </TR>
  )
}

export default Product

const TD = styled.td`
  border-radius:0;
  border-bottom:1px solid #f6f6f6;
`
const TDR = styled(TD)`
  text-align:right;
`
const H1 = styled.h1`
  max-width:100%;
  text-align:center;
  margin:5vh 0;
`
const TR = styled.tr`
  cursor:pointer;
`
const ProductContainer = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:0;
  margin:0;
  gap:20px;
  flex-wrap:wrap;
  flex:0;
`

