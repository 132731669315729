import { createAsyncThunk,createSlice } from '@reduxjs/toolkit'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { parseCollectionSnapshot } from '../../utils/firebaseUtils'

const name = `users`
let unsubscribe

export const subscribeToUsers = createAsyncThunk(`${name}/subscribe`,async(_,{ getState,dispatch }) => {
  if(unsubscribe) unsubscribe()
  unsubscribe = firebase.firestore().collection(`users`).onSnapshot(snapshot => {
    dispatch(setUsers(parseCollectionSnapshot(snapshot)))
  })
},{ condition: () => !!firebase.auth().currentUser })

const slice = createSlice({
  name,
  initialState: {
    status: `idle`,
    data: [],
    error: null,
  },
  reducers: {
    setUsers(state,action){
      state.data = action.payload
    },
  },
})

export default slice.reducer
export const { setUsers } = slice.actions
export const selectUsers = state => state[name].data

