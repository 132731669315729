import React,{ useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectTeams } from '../redux/slices/teams.slice'
import Member from './member'
import AddMember from './addMember'
import TeamName from './teamName'
import { X } from 'react-feather'
import { useFB } from '../hooks/useFirebase'
import { toast } from 'react-hot-toast'
import { successStyle } from '../utils/toast.format'
import Search from './search'

const TeamsTable = () => {
  const teams = useSelector(selectTeams)
  const [value,setValue] = useState(``)
  const fb = useFB()
  const handleDelete = async team => {
    const confirm = window.confirm(`Do you want to delete this team?`)
    if(confirm){
      await fb.doc(`teams/${team.id}`).delete()
      toast(`Team wurde gelöscht`,successStyle)
    }
  }

  return (
    <Container>
      <Search style={{ marginBottom: 10 }} onChange={setValue}/>
      {teams
        .filter(team => {
          if(!value) return team
          const haystack = []
          if(team.members && team.members.length) haystack.push(...team.members)
          if(team.name) haystack.push(team.name)
          return haystack.map(t => t.toLowerCase()).join(` `).includes(value.toLowerCase())
        })
        .map((team,index) => {
          return (
            <Team key={index}>
              <TeamName team={team}/>
              <Members>
                {team.members && team.members.map((member,index) => {
                  return(
                    <Member key={index} team={team} member={member}/>
                  )
                })}
                <AddMember team={team}/>
              </Members>
              <StyledX size={64} className="delete" onClick={() => handleDelete(team)}/>
            </Team>
          )
        })}
    </Container>
  )
}

export default TeamsTable 

const Container = styled.div`
  display:flex;
  flex-direction:column;
  margin-top:20px;
  &:hover .delete{
    opacity:1;
  }
`
const Team = styled.div`
  background-color:lightgray;
  margin:5px 10px;
  padding:10px;
  box-sizing:border-box;
`
const Members = styled.div`
  flex-direction:column;
  flex-grow:1;
  align-items:flex-start;
  gap:10px;
`
const StyledX = styled(X)`
  cursor: pointer;
  margin:0 20px;
  opacity:0;
  transition: opacity 0.3s ease-in-out;
  font-size:1rem;
`

