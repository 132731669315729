export const calculateTransport = (total,products) => {
  if(total >= 600) return 0
  let weight = 0
  products.filter(Boolean).forEach(product => {
    const { width,height,thickness } = product
    if(width && height && thickness)
      weight += width * height * thickness / 1000 / 1000 * 2.5 * product.quantity
  })
  if(weight < 50) return 30
  return 76
}