import { createSelector } from '@reduxjs/toolkit'
import { selectUserSearchTerm,selectUserSortAsc,selectUserSortBy } from '../slices/options.slice'
import { selectUsers } from '../slices/users.slice'
import sortArray from 'sort-array'

export const selectSearchedForUsers = createSelector(
  [selectUsers,selectUserSearchTerm],
  (users,searchTerm) => {
    searchTerm = searchTerm.toLowerCase()
    return users.filter(u => !searchTerm || 
      u.email.includes(searchTerm) || 
      `${u.firstname} ${u.lastname}`.includes(searchTerm) ||
      u.role.includes(searchTerm)
    )
  }
)

export const selectSortedUsers = createSelector(
  [selectSearchedForUsers,selectUserSortBy,selectUserSortAsc],
  (users,sortBy,sortAsc) => {
    return sortArray([...users],{
      by: sortBy,
      order: sortAsc ? `asc` : `desc`,
      computed: { name: row => `${row.firstname} ${row.lastname}` },
    })
  }
)
