import React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { highlight } from '../utils/colors'

const IconButton = ({ Icon,hoverText,onClick }) => {
  return (
    <Container
      className="disappear-in-pdf"
      onClick={onClick}>
      <Icon 
        className="styled-icon"
        size={16}
        data-tip={hoverText}/>
      <ReactTooltip delayShow={200}/>
    </Container>
  )
}

export default IconButton 

const Container = styled.div`
  flex-grow:0;
  opacity:1;
  background-color:black;
  color:white;
  border-radius:40px;
  padding:5px;
  /* transition: opacity 0.3s; */
  cursor: pointer;
  .styled-icon{
    color:white;
    &:focus{
      outline:none;
      box-shadow: 0 0 3pt 2pt ${highlight};
    }
  }
`



