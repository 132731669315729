import React from 'react'
import styled from 'styled-components'
import { useSelector,useDispatch } from 'react-redux'
import { selectDomains } from '../redux/slices/unitPrices.slice'
import ButtonGroupHorizontal from './buttonGroupHorizontal'
import { selectSelectedDomains,setSelectedDomains } from '../redux/slices/options.slice'

const DomainSelector = () => {
  const dispatch = useDispatch()
  const domains = useSelector(selectDomains)
  const selectedDomains = useSelector(selectSelectedDomains)

  const options = domains.map(d => ({
    value: d,
    label: d,
  }))

  const handleChange = change => {
    const updates = selectedDomains.includes(change)
      ? [...selectedDomains].filter(d => d !== change)
      : [...selectedDomains,change]
    dispatch(setSelectedDomains(updates))
  }
  return (
    <Container>
      <ButtonGroupHorizontal
        value={selectedDomains}
        options={options}
        onChange={handleChange}/>
    </Container>
  )
}

export default DomainSelector 

const Container = styled.div`
  display:flex;
`

