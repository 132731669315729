import styled from 'styled-components'
import NumberInput from './numberInput'
import React from 'react'
import { useParams } from 'react-router'
import { selectQuote,updateProduct } from '../redux/slices/quote.slice'
import { useDispatch,useSelector } from 'react-redux'

const HolesNumberInput = () => {
  const { productId } = useParams()
  const quote = useSelector(selectQuote)
  const product = quote.products[Number(productId)] || {}
  const dispatch = useDispatch()

  const handleChange = value => {
    dispatch(updateProduct({ productId,model: value }))
  }

  return (
    <Container>
      <NumberInput
        label="Anzahl"
        units=""
        value={product.numHoles || 0}
        editable
        onChange={handleChange}/>
    </Container>
  )
}

export default HolesNumberInput 

const Container = styled.div`
  max-width:300px;
`

