import styled from 'styled-components'
import ButtonGroup from './buttonGroup'
import { selectHoleTypes } from '../redux/selectors/holeTypes.selector'
import OptionGroup from './optionGroup'
import HolesNumberInput from './holesNumberInput'
import React from 'react'
import { useParams } from 'react-router'
import { selectQuote,updateProduct } from '../redux/slices/quote.slice'
import { useDispatch,useSelector } from 'react-redux'

const HoleOptions = () => {

  const { productId } = useParams()
  const quote = useSelector(selectQuote)
  const product = quote.products[Number(productId)] || {}
  const dispatch = useDispatch()

  const handleChange = value => {
    let numHoles = product.numHoles || 0
    if(!product.holeType)
      numHoles = window.prompt(`Wie viele Löcher benötigen Sie?`)
    dispatch(updateProduct({ productId,holeType: value,numHoles }))
  }

  const holeTypes = useSelector(selectHoleTypes)
  const optionise = o => ({ value: o,label: o })
  const options = holeTypes.map(optionise)

  return (
    <Container>
      <OptionGroup label="Löcher">
        <ButtonGroup
          label="Lochart"
          onChange={handleChange}
          value={product.holeType}
          editable={product.editable}
          options={[{ value: false,label: `Keine` },...options]}/>
        <HolesNumberInput/>
      </OptionGroup>
    </Container>
  )
}

export default HoleOptions 

const Container = styled.div`
  display:flex;
  max-width:300px;
`

