import React from 'react'
import { useDispatch,useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectQuotesSlice } from '../redux/slices/quotes.slice'
import { NoData } from './ordersTable'
import { useHistory } from 'react-router-dom'
import DeleteQuote from './deleteQuote'
import EditQuote from './editQuote'
import DuplicateQuote from './duplicateQuote'
import Search from './search'
import Spinner from './spinner'
import { setSearchTerm } from '../redux/slices/options.slice'
import Pagination from './pagination'
import SortCarat from './sortCarat'
import { formatCurrency,formatDatetime } from '../utils/formatter'
import { selectQuotesPage,selectSearchedForQuotes } from '../redux/selectors/quotesTableData.selector'
// import OrderIcon from './orderIcon'
import { useUser } from '../hooks/useUser'

const QuotesTable = () => {
  const { status } = useSelector(selectQuotesSlice)
  const user = useUser()
  const quotes = useSelector(selectQuotesPage)
  const searchedForQuotes = useSelector(selectSearchedForQuotes)
  const history = useHistory()
  const dispatch = useDispatch()
  const handleClick = quote => history.push(`/quotes/${quote.id}`) 
  if(status === `idle`) return <Spinner/>

  return (
    <Container>
      <Search onChange={word => dispatch(setSearchTerm(word))} />
      {!quotes.length && <NoData>Es gibt keine Angebote</NoData>}
      {!! quotes.length &&
      <React.Fragment>
        <Table>
          <thead>
            <tr>
              <SortCarat heading="Angebotsnummer" column="id"/>
              <SortCarat heading="Kundenreferenz" column="clientReference"/>
              <SortCarat heading="Erstellt von" column="createdBy"/>
              <SortCarat heading="Erstellt um" column="createdAt"/>
              <SortCarat heading="Produktanzahl" column="numProducts" align="right"/>
              <SortCarat heading="Total" column="total" align="right"/>
              {user.role === `admin` && <SortCarat heading="Status" column="status" align="right"/>}
              <THR></THR>
              <THR></THR>
              <THR></THR>
              {/* <THR></THR> */}
            </tr>
          </thead>
          <tbody>
            {quotes
              .filter(quote => {
                if(user.role === `admin`) return true
                return quote.status !== `deleted`
              })
              .map((quote,index) => {
                const { createdAt,createdBy,clientReference,total,status } = quote
                return (
                  <tr key={index}>
                    <TDL onClick={() => handleClick(quote)}>{quote.id}</TDL>
                    <TD>{clientReference || ``}</TD>
                    <TD>{createdBy}</TD>
                    <TD>{formatDatetime(createdAt)}</TD>
                    <TDR>{quote.products ? quote.products.reduce((acc,cur) => acc + cur.quantity,0) : 0}</TDR>
                    <TDR>{ formatCurrency(total)}</TDR>
                    {user.role === `admin` && <Status status={status}>{status}</Status>}
                    <TDR><EditQuote quote={quote}/></TDR>
                    <TDR><DuplicateQuote quote={quote}/></TDR>
                    {quote.status !== `deleted` && <TDR><DeleteQuote quote={quote}/></TDR>}
                    {/* <TDR><OrderIcon quote={quote}/></TDR> */}
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </React.Fragment>
      }
      <Pagination numItems={searchedForQuotes.length}/>
    </Container>
  )
}

export default QuotesTable 

const Container = styled.div`
  display:flex;
  padding:0px;
  overflow-x: auto;
  /* max-width:100%; */
  box-sizing:content-box;
  justify-content:unset;
  align-items:unset;
  flex-direction:column;
`
const Table = styled.table`
  flex-grow:1;
`
const TH = styled.th`
  border-bottom:1px solid #f6f6f6;
  text-align:left;
`
const THR = styled(TH)`
  text-align:right;
`
const TD = styled.td`
  border-radius:0;
  border-bottom:1px solid #f6f6f6;
`
const TDL = styled(TD)`
  cursor:pointer;
`
const TDR = styled(TD)`
  text-align:right;
`
const Status = styled(TD)`
  text-align:center;
  color:${p => p.status === `deleted` ? `red` : ``};
`



