import React,{ useState } from 'react'
import styled from 'styled-components'
import { useFB } from '../hooks/useFirebase'
import { FieldValue } from '../utils/firebaseUtils'

const AddMember = ({ team }) => {
  const [value,setValue] = useState(``)
  const fb = useFB()

  const handleKeyUp = e => {
    const { key } = e
    if(key === `Enter`) 
      fb.doc(`teams/${team.id}`)
        .update({ members: FieldValue.arrayUnion(value) })
  }
  return (
    <Input
      placeholder="add team member"
      onKeyUp={handleKeyUp}
      value={value}
      onChange={e => setValue(e.target.value)}/>
  )
}

export default AddMember 

const Input = styled.input`
  border-radius:40px;
`

