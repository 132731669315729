import styled from 'styled-components'
import ButtonGroup from './buttonGroup'
import OptionGroup from './optionGroup'
import React from 'react'
import { useParams } from 'react-router'
import { selectQuote,updateProduct } from '../redux/slices/quote.slice'
import { useDispatch,useSelector } from 'react-redux'

const TaperedOption = () => {
  const { productId } = useParams()
  const quote = useSelector(selectQuote)
  const product = quote.products[Number(productId)] || {}
  const dispatch = useDispatch()

  const handleChange = value => {
    dispatch(updateProduct({ productId,tapered: value }))
  }

  const options = [
    {
      value: false,
      label: `Nein`,
    },
    {
      value: true,
      label: `Ja`,
    },
  ]

  return (
    <Container>
      <OptionGroup label="Facettenschliff">
        <ButtonGroup
          value={product.tapered}
          options={options}
          editable={product.editable}
          onChange={handleChange}/>
      </OptionGroup>
    </Container>
  )
}

export default TaperedOption 

const Container = styled.div`
  max-width:300px;
  flex-grow:0;

`

