import React from 'react'
import styled from 'styled-components'
import ReactToggle from 'react-toggle'
import 'react-toggle/style.css'
import { useFB } from '../hooks/useFirebase'

const UserStatus = ({ user }) => {
  const fb = useFB()
  const handleChange = () => {
    const status = user.status === `active` ? `pending` : `active`
    fb.doc(`users/${user.id}`).update({ status })
  }
  return (
    <Container>
      <ReactToggle
        style={{ flexGrow: 0 }}
        checked={user.status === `active`}
        onChange={handleChange}/> 
    </Container>
  )
}

export default UserStatus 

const Container = styled.div`
  display:flex;
  margin:auto;
  width:auto;
  flex-grow:0;
  justify-content:center;
`