import { configureStore,getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas/rootSaga'

import userReducer from './slices/user.slice'
import usersReducer from './slices/users.slice'
import teamsReducer from './slices/teams.slice'
import ordersReducer from './slices/orders.slice'
import quotesReducer from './slices/quotes.slice'
import quoteReducer from './slices/quote.slice'
import unitPricesReducer from './slices/unitPrices.slice'
import optionsReducer from './slices/options.slice'
import faqsReducer from './slices/faqs.slice'

export const sagaMiddleware = createSagaMiddleware()

export default configureStore({
  reducer: { 
    user: userReducer,
    users: usersReducer,
    teams: teamsReducer,
    quote: quoteReducer,
    quotes: quotesReducer,
    orders: ordersReducer,
    options: optionsReducer,
    unitPrices: unitPricesReducer,
    faqs: faqsReducer,
  },
  middleware: [...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),sagaMiddleware],
})

sagaMiddleware.run(rootSaga)

