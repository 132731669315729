import React from 'react'
import styled from 'styled-components'
import { useDispatch,useSelector } from 'react-redux'
import { selectPage,selectPerPage,setPage } from '../redux/slices/options.slice'
import Button from './button'
import { toast } from 'react-hot-toast'
import { infoStyle } from '../utils/toast.format'

const Pagination = ({ numItems }) => {
  const page = useSelector(selectPage)
  const perPage = useSelector(selectPerPage)

  const nextDisabled = perPage * (page + 1) > numItems
  const previousDisabled = page === 0
  const dispatch = useDispatch()
  return (
    <Container>
      <Button
        text={`<`}
        style={{ backgroundColor: previousDisabled ? `gray` : `` }}
        onClick={() => {
          if(previousDisabled)return toast(`Sie sind auf der ersten Seite`,infoStyle)
          dispatch(setPage(Math.max(0,page - 1)))
        }}/>
      <Page>{`Seite ${page + 1}`}</Page>
      <Button 
        text={`>`}
        style={{ backgroundColor: nextDisabled ? `gray` : `` }}
        onClick={() => {
          if(nextDisabled) return toast(`Sie sind auf der letzten Seite`,infoStyle)
          dispatch(setPage(page + 1))
        }}/>
    </Container>
  )
}

export default Pagination 

const Container = styled.div`
  display:flex;
`
const Page = styled.div`
  display:flex;
`

