import { createSlice } from '@reduxjs/toolkit'

export const defaultProduct = {
  rawMaterial: null,
  width: 500,
  height: 600,
  thickness: 5,
  hangingType: false,
  hookType: false,
  holeType: false,
  tapered: false,
  model: `square`,
  quantity: 1,
}

export const initialState = {
  clientReference: ``,
  products: [
    defaultProduct,
  ],
}

const name = `quote`
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setQuote(state,action){
      state = action.payload
      return state
    },
    addProduct(state,action){
      state.products.push(action.payload)
    },
    deleteProduct(state,action){
      state.products.splice(action.payload,1)
    },
    updateProduct(state,action){
      const { productId,...updates } = action.payload
      state.products[Number(productId)] = {
        ...state.products[Number(productId)],
        ...updates,
      }
    },
    resetQuote(state,action){
      state = initialState
      return state
    },
  },
})

export default slice.reducer
export const { 
  setQuote,
  addProduct,
  deleteProduct,
  updateProduct,
  resetQuote,
} = slice.actions
export const selectQuote = state => state[name]

