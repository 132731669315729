import React from 'react'
import styled from 'styled-components'
import 'react-toggle/style.css'
import { useFB } from '../hooks/useFirebase'
import ButtonGroupHorizontal from './buttonGroupHorizontal'
import { toast } from 'react-hot-toast'
import { successStyle } from '../utils/toast.format'

const UserStatus = ({ user }) => {
  const fb = useFB()
  const handleChange = async change => {
    await fb.doc(`users/${user.id}`).update({ role: change })
    toast(`${user.email} ist jetzt ein ${change}`,successStyle)
  }

  const options = [
    {
      value: `quoter`,
      label: `Quoter`,
    },
    // {
    //   value: `orderer`,
    //   label: `Besteller`,
    // },
    {
      value: `admin`,
      label: `Admin`,
    },
  ]

  return (
    <Container>
      <ButtonGroupHorizontal
        value={[user.role]}
        onChange={handleChange}
        options={options}/>
    </Container>
  )
}

export default UserStatus 

const Container = styled.div`
  display:flex;
  margin:auto;
  width:auto;
  flex-grow:0;
  justify-content:center;
`


