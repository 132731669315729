import React,{ useRef,useState } from 'react'
import styled from 'styled-components'

const Search = ({ onChange,style }) => {
  const [value,setValue] = useState(``)
  const ref = useRef()

  const handleChange = e => {
    const { value } = e.target
    setValue(value)
    onChange(value)    
  }

  const handleKeyUp = e => {
    if(e.key === `Escape`) ref.current.blur()
    if(e.key === `Enter`) ref.current.blur()
  }

  return (
    <Container style={style}>
      <Input
        ref={ref}
        placeholder="Search..."
        value={value} 
        onKeyUp={handleKeyUp}
        onChange={handleChange}/> 
    </Container>
  )
}

export default Search 

const Container = styled.div`
  display:flex;
`
const Input = styled.input`
  border:thin solid lightgray;
  border-radius:40px;
  margin:10px;
`

