import React from 'react'
import ReactModal from 'react-modal'
import { X } from 'react-feather'
import styled from 'styled-components'

const Modal = ({ show,close,children }) => {
  return (
    <ReactModal
      isOpen={show}
      contentLabel="onRequestClose Example"
      onRequestClose={close}
      className="Modal"
      overlayClassName="Overlay">
      <XBox>
        <StyledX onClick={close}/>
      </XBox>
      {children}
    </ReactModal>
  )
}

export default Modal 

const XBox = styled.div`
  justify-content:flex-end;
  align-items:flex-end;
`
const StyledX = styled(X)`
  align-self:flex-end;
  cursor: pointer;
`
