export const getLineItems = (product,unitPrices) => {
  const lineItems = []
  if(!product) return lineItems

  const {
    rawMaterial,holeType,numHoles,
    tapered,model,hangingType,
    width,height,thickness,
  } = product
  
  const squareMeters = width && height ? Number(width) / 1000 * Number(height) / 1000 : 0

  if(rawMaterial){
    const biggerDimension = Math.max(width,height)
    const smallerDimension = Math.min(width,height)
    let unitPrice = unitPrices.find(u => u.model === model && Number(u.height) === Number(height) && Number(u.width) === Number(width))
    if(!unitPrice)
      // eslint-disable-next-line prefer-destructuring
      unitPrice = unitPrices
        .filter(u => !u.model)
        .filter(u => u.rawMaterial === rawMaterial)
        .filter(u => Number(u.thickness) === Number(thickness))
        .map(u => ({
          ...u,
          biggerDimension: Math.max(u.width,u.height),
          smallerDimension: Math.min(u.width,u.height),
        }))
        .sort((a,b) => a.biggerDimension - b.biggerDimension)
        .filter(u => u.biggerDimension >= biggerDimension)
        .sort((a,b) => a.smallerDimension - b.smallerDimension)
        .filter(u => u.smallerDimension >= smallerDimension)[0]
    if(unitPrice) lineItems.push({ unitPrice,quantity: 1 }) 
  }

  if(holeType){
    const unitPrice = unitPrices.find(u => u.holeType === holeType)
    if(unitPrice) lineItems.push({ unitPrice,quantity: numHoles || 0 }) 
  }

  if(hangingType){
    const unitPrice = unitPrices
      .filter(u => u.hangingType === hangingType)
      .sort((a,b) => Number(b.squareMeters) - Number(a.squareMeters))
      .find(u => Number(u.squareMeters) <= squareMeters)
    if(unitPrice)
      lineItems.push({
        unitPrice,
        quantity: 1,
      })
  }

  const bruttoTotal = lineItems.reduce((acc,cur) => {
    return acc += Number(cur.quantity) * Number(cur.unitPrice.price)
  },0)

  if(tapered){
    const unitPrice = unitPrices.find(u => u.facettenschliff)
    if(unitPrice){
      let { price } = unitPrice
      if(typeof price === `string`)
        price = Number(price.replace(`%`,``)) / 100
      if(price)
        lineItems.push({
          unitPrice: { 
            ...unitPrice,
            price: bruttoTotal * price,
          },
          quantity: 1,
        })
    }      
  }

  if(model === `round`)
    if(!unitPrices.find(u => u.model === model && Number(u.height) === Number(height) && Number(u.width) === Number(width))){
      const unitPrice = unitPrices.find(u => {
        return u.model === `round` && !u.width && !u.height
      })
      if(unitPrice){
        let { price } = unitPrice
        if(typeof price === `string`)
          price = Number(price.replace(`%`,``)) / 100
        if(price)
          lineItems.push({
            unitPrice: { 
              ...unitPrice,
              price: bruttoTotal * price,
            },
            quantity: 1,
          })
      }      
    }      
        
  

  if(model === `other`){
    const unitPrice = unitPrices.find(u => u.model === `other`)
    if(unitPrice){
      let { price } = unitPrice
      if(typeof price === `string`)
        price = Number(price.replace(`%`,``)) / 100
      if(price)
        lineItems.push({
          unitPrice: { 
            ...unitPrice,
            price: bruttoTotal * price,
          },
          quantity: 1,
        })
    }      
  }

  return lineItems
}
