import React from 'react'
import { useDispatch,useSelector } from 'react-redux'
import styled from 'styled-components'
import UserRole from './userRole'
import UserStatus from './userStatus'
import Search from './search'
import { setUserSearchTerm } from '../redux/slices/options.slice'
import { selectSortedUsers } from '../redux/selectors/usersTableData.selector'
import UserSortCarat from './userSortCarat'

const UsersTable = () => {
  const users = useSelector(selectSortedUsers)
  const dispatch = useDispatch()
  return (
    <Container>
      <Search onChange={word => dispatch(setUserSearchTerm(word))} />
      <Table>
        <thead>
          <tr>
            <UserSortCarat heading="Name" column="name"/>
            <UserSortCarat heading="Email" column="email"/>
            <UserSortCarat heading="Active?" column="status"/>
            <UserSortCarat heading="Rolle" column="role"/>
          </tr>
        </thead>
        <tbody>
          {users.map((user,index) => {
            const { firstname,lastname,email } = user
            return (
              <tr key={index}>
                <Td>{`${firstname || ``} ${lastname || ``}`}</Td>
                <Td>{email}</Td>
                <Td><UserStatus user={user}/></Td>
                <Td><UserRole user={user}/></Td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

export default UsersTable 

const Container = styled.div`
  display:flex;
  flex-direction:column;
  padding:0px;
  overflow-x: auto;
  box-sizing:content-box;
  justify-content:unset;
  align-items:unset;
`
const Table = styled.table`
  flex-grow:1;
`
const Td = styled.td`
  text-align:center;
`

