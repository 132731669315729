import React from 'react'
import styled from 'styled-components'
import ButtonGroup from './buttonGroup'
import { useDispatch,useSelector } from 'react-redux'
import { selectRawMaterialOptions } from '../redux/selectors/rawMaterialOptions.selector'
import OptionGroup from './optionGroup'
import { selectUnitPrices } from '../redux/slices/unitPrices.slice'
import { useParams } from 'react-router'
import { selectQuote,updateProduct } from '../redux/slices/quote.slice'

const MirrorOptions = () => {
  const { productId } = useParams()
  const quote = useSelector(selectQuote)
  const product = quote.products[Number(productId)] || {}
  const dispatch = useDispatch()
  const unitPrices = useSelector(selectUnitPrices)
  const rawMaterialOptions = useSelector(selectRawMaterialOptions)
  const optionise = o => ({ value: o,label: o })
  const options = [{ label: `Bitte wählen`,value: null },...rawMaterialOptions.map(optionise)]

  const handleChange = value => {
    const prices = unitPrices
      .filter(u => u.rawMaterial === value)

    let thickness
    if(prices.length)
    //eslint-disable-next-line
      thickness = prices.sort((a,b) => a.thickness - b.thickness)[0].thickness

    if(value === `Kristallspiegel`) thickness = 5
    if(value === `Sicherheitsdoppelspiegel`) thickness = 6
    if(value === `Miralite-Safe Sicherheitsspiegel`) thickness = 5

    dispatch(updateProduct({ 
      productId,
      rawMaterial: value,
      thickness, 
    }))
  }

  return (
    <Container>
      <OptionGroup label="Spiegeltyp">
        <ButtonGroup
          onChange={handleChange}
          value={product.rawMaterial}
          editable
          options={options}/>
      </OptionGroup>
    </Container>
  )
}

export default MirrorOptions 

const Container = styled.div`
  max-width:300px;
`

