import { createAsyncThunk,createSlice } from '@reduxjs/toolkit'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const name = `user`
let unsubscribe

export const subscribeToUser = createAsyncThunk(`${name}/subscribe`,async(_,{ rejectWithValue,getState,dispatch }) => {
  dispatch(setUser({}))
  if(unsubscribe) unsubscribe()
  const { email } = firebase.auth().currentUser
  if(email)
    unsubscribe = firebase.firestore().doc(`users/${email}`).onSnapshot(snapshot => {
      dispatch(setUser(snapshot.data()))
    })
})

const slice = createSlice({
  name,
  initialState: {
    status: `idle`,
    data: {},
    error: null,
  },
  reducers: {
    setUser(state,action){
      state.data = action.payload
    },
  },
})

export default slice.reducer
export const { setUser } = slice.actions
export const selectUser = state => state[name].data

