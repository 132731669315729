import firebase from 'firebase/app'
import 'firebase/auth'
import { useDispatch } from 'react-redux'
import { setOrders } from '../redux/slices/orders.slice'
import { setQuotes } from '../redux/slices/quotes.slice'
import { setTeams } from '../redux/slices/teams.slice'
import { setUnitPrices } from '../redux/slices/unitPrices.slice'
import { setUser } from '../redux/slices/user.slice'
import { setUsers } from '../redux/slices/users.slice'
import { useHistory } from 'react-router'

const Logout = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  dispatch(setQuotes([]))
  dispatch(setTeams([]))
  dispatch(setOrders([]))
  dispatch(setUsers([]))
  dispatch(setUser({}))
  dispatch(setUnitPrices([]))
  firebase.auth().signOut()
  history.push(`/login`)
  return null
}

export default Logout 

