import styled from 'styled-components'
import NumberInput from './numberInput'
import React from 'react'
import { useParams } from 'react-router'
import { selectQuote,updateProduct } from '../redux/slices/quote.slice'
import { useDispatch,useSelector } from 'react-redux'

const QuantityInput = () => {
  const { productId } = useParams()
  const quote = useSelector(selectQuote)
  const product = quote.products[Number(productId)] || {}
  const dispatch = useDispatch()

  const handleChange = value => {
    dispatch(updateProduct({ productId,quantity: value }))
  }

  const handleKeyUp = e => {
    if(e.key === `Enter`) e.target.blur()
  }

  return (
    <Container>
      <NumberInput
        label="Anzahl Spiegel"
        type="number"
        value={product.quantity}
        onKeyUp={handleKeyUp}
        onChange={handleChange}/>
    </Container>
  )
}

export default QuantityInput 

const Container = styled.div`
  max-width:300px;
  flex-grow:0;
`