import AddProduct from './addProduct'
import React from 'react'
import styled from 'styled-components'
import { formatCurrency } from '../utils/formatter'
import { getLineItems } from '../utils/getLineItems'
import { selectUnitPrices } from '../redux/slices/unitPrices.slice'
import { useSelector } from 'react-redux'
import { calculateTransport } from '../utils/calculateTransport'
import Product from './product'
import { selectQuote } from '../redux/slices/quote.slice'

const ProductsTable = () => {
  const quote = useSelector(selectQuote)
  const unitPrices = useSelector(selectUnitPrices)
  const { products = [] } = quote

  const totalPreTransport = products 
    .reduce((acc,cur) => {
      let { quantity } = cur 
      const lineItems = getLineItems(cur,unitPrices)
      const unitPrice = lineItems.reduce((acc,cur) => {
        return acc += Number(cur.unitPrice.price) * Number(cur.quantity)
      },0)    
      return acc += Number(unitPrice || 0) * Number(quantity || 0)
    },0)

  
  const transport = calculateTransport(totalPreTransport,products)

  const total = totalPreTransport + transport

  if(!quote) return null
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <TH>Position</TH>
            <TH>Beschreibung</TH>
            <THR>Anzahl</THR>
            <THR>Stückpreis</THR>
            <THR>Total</THR>
            {quote.editable && <TH></TH>}
            {quote.editable && <TH></TH>}
          </tr>
        </thead>
        <tbody>
          {products
            .map((product,index) => {
              return (
                <Product key={index} index={index} product={product}/>
              )
            })}
          {!!products.length && <tr>
            <TD>Transport</TD>
            <TD>Lieferkosten (kostenlos ab €600)</TD>
            <TD></TD>
            <TD></TD>
            <TDR>{formatCurrency(transport)}</TDR>
            {quote.editable && <TD></TD>}
            {quote.editable && <TD></TD>}
          </tr>}
          <tr>
            <TD><AddProduct quote={quote}/></TD>
            <TD></TD>
            <TD></TD>
            <TDR>Total:</TDR>
            <TDT>{!!products.length && formatCurrency(total)}</TDT>
            {quote.editable && <TD></TD>}
            {quote.editable && <TD></TD>}
          </tr>
        </tbody>
      </Table> 
    </Container>
  )
}

export default ProductsTable 

const Container = styled.div`
  padding:0px;
  margin-top:60px;
  overflow-x: auto;
  max-width:100%;
  box-sizing:content-box;
  justify-content:unset;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

`
const Table = styled.table`
  flex-grow:1;
`
const TH = styled.th`
  border-bottom:1px solid #f6f6f6;
  text-align:left;
  font-weight:800;
`
const THR = styled(TH)`
  text-align:right;
`
const TD = styled.td`
  border-radius:0;
  border-bottom:1px solid #f6f6f6;
`
const TDR = styled(TD)`
  text-align:right;
`
const TDT = styled(TD)`
  text-align:right;
  border-top:1px solid black;
`

