import { createSelector } from '@reduxjs/toolkit'
import { selectHeightFilter,selectProductFilter,selectThicknessFilter,selectWidthFilter } from '../slices/options.slice'
import { selectUnitPrices } from '../slices/unitPrices.slice'

export const selectShownUnitPrices = createSelector(
  [selectUnitPrices,selectProductFilter,selectThicknessFilter,selectHeightFilter,selectWidthFilter],
  (unitPrices,productFilter,thicknessFilter,heightFilter,widthFilter) => {
    let output = [...unitPrices]
    if(productFilter.length) 
      output = output.filter(u => {
        if(productFilter.includes(`Other`)) return productFilter.includes(u.rawMaterial) || !u.rawMaterial
        return productFilter.includes(u.rawMaterial)
      })
    if(thicknessFilter.length) output = output.filter(u => thicknessFilter.includes(u.thickness))
    if(heightFilter.length) output = output.filter(u => heightFilter.includes(Number(u.height)))
    if(widthFilter.length) output = output.filter(u => widthFilter.includes(Number(u.width)))
    return output
  },
)