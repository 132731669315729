import firebase from 'firebase/app'
import 'firebase/firestore'

export const parseCollectionSnapshot = snapshot => {
  const output = []
  snapshot.forEach(item => {
    output.push(parseDocSnapshot(item))
  })
  return output
}

export const parseDocSnapshot = snapshot => {
  const data = snapshot.data()
  return{
    id: snapshot.id,
    createdAtSeconds: data.createdAt
      ? data.createdAt.seconds
      : 0,
    ...data,
  }
}


export const { FieldValue } = firebase.firestore