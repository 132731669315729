import React from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import Carousel from './carousel'
import Button from './button'
import CreateQuote from './createQuote'
import { useHistory } from 'react-router-dom'
import { darkgray } from '../utils/colors'
import { useUser } from '../hooks/useUser'

const Landing = () => {
  const history = useHistory()
  const user = useUser()
  return (
    <MainLayout>
      <Container>
        {user.firstname && user.lastname && <Title>{`Hallo ${user.firstname} ${user.lastname}`}</Title>}
        <Title style={{ marginTop: 0 }} gray={!!user.email}>{`Willkommen ${user.email ? `zurück` : ``} bei Vandaglas`}</Title>
        <Buttons>
          <CreateQuote/>
          <LinkContainer>
            <Button
              text="Angebote verwalten"
              style={{ backgroundColor: darkgray }}
              onClick={() => history.push(`/quotes`)}/>
          </LinkContainer>
        </Buttons>
        <Carousel/>
      </Container>
    </MainLayout>
  )
}

export default Landing 

const Container = styled.div`
  flex-direction:column;
`
const Title = styled.h1`
  padding:10px;
  margin-bottom:0;
  margin-top:3vh;
  font-size:3rem;
  color: ${p => p.gray ? darkgray : ``}
`
const Buttons = styled.div`
  margin-top:30px;
  max-width:550px;
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
`
const LinkContainer = styled.div`
  display:flex;
  flex-direction:column;
`

