import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import DeleteUnitPrice from './deleteUnitPrice'
import { selectSelectedDomains } from '../redux/slices/options.slice'
import { selectShownUnitPrices } from '../redux/selectors/shownUnitPrices.selector'
import Price from './price'

const UnitPricesTable = () => {
  const unitPrices = useSelector(selectShownUnitPrices)
  const domains = useSelector(selectSelectedDomains)
  if(!unitPrices || !unitPrices.length) return null
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <TH>Product Nummer</TH>
            <TH>Beschreibung</TH>
            <THR>Stärke<MM/></THR>
            <THR>Höhe<MM/></THR>
            <THR>Breite<MM/></THR>
            <THR>Durchmesser<MM/></THR>
            <THR>Area<M2/></THR>
            <THR>Standard Preis<EUR/></THR>
            {domains.map((key,index) => {
              return (
                <THR key={index}><Gray>Price for </Gray>{key}<EUR/></THR>
              )
            })}
            <THR></THR>
          </tr>
        </thead>
        <tbody>
          {unitPrices
            .filter(p => p.productNumber)
            .map((unitPrice,index) => {
              const {
                productNumber,thickness,squareMeters,diameter,description,
                width,height, 
              } = unitPrice
              return (
                <tr key={index}>
                  <TD>{productNumber}</TD>
                  <TD>{description}</TD>
                  <TDR>{thickness}</TDR>
                  <TDR>{height}</TDR>
                  <TDR>{width}</TDR>
                  <TDR>{diameter}</TDR>
                  <TDR>{squareMeters}</TDR>
                  <TDR><Price unitPrice={unitPrice} price="standardPrice"/></TDR>
                  {domains.map((key,index) => {
                    return (
                      <TDR key={index}><Price unitPrice={unitPrice} price={key}/></TDR>
                    )
                  })}
                  <TDR><DeleteUnitPrice unitPrice={unitPrice}/></TDR>
                </tr>
              )
            })}
        </tbody>
      </Table> 
    </Container>
  )
}

export default UnitPricesTable 

const MM = () => <span style={{ color: `#c2c2c2`,fontWeight: 400 }}> mm</span>
const M2 = () => <span style={{ color: `#c2c2c2`,fontWeight: 400 }}> m²</span>
const EUR = () => <span style={{ color: `#c2c2c2`,fontWeight: 400 }}> €</span>

const Container = styled.div`
  display:flex;
  padding:0px;
  overflow-x: auto;
  max-width:100%;
  box-sizing:content-box;
  justify-content:unset;
`
const TH = styled.th`
  border-bottom:1px solid #f6f6f6;
  text-align:left;
`
const THR = styled(TH)`
  text-align:right;
`
const Table = styled.table`
  margin:20px 0;
  flex-grow:1;
`
const TD = styled.td`
  border-radius:0;
  border-bottom:1px solid #f6f6f6;
`
const TDR = styled(TD)`
  text-align:right;
`
const Gray = styled.span`
  color:gray;
`


