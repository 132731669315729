import { createSelector } from '@reduxjs/toolkit'
import { formatCurrency,formatDatetime } from '../../utils/formatter'
import { selectPage,selectPerPage,selectSearchTerm,selectSortAsc,selectSortBy } from '../slices/options.slice'
import { selectQuotes } from '../slices/quotes.slice'
import { getLineItems } from '../../utils/getLineItems'
import sortArray from 'sort-array'
import { getUnitPricesForDomain,selectRawUnitPrices } from '../slices/unitPrices.slice'
import { getDomain } from '../../utils/getDomain'
import { calculateTransport } from '../../utils/calculateTransport'

export const selectQuotesWithTotals = createSelector(
  [selectQuotes,selectRawUnitPrices],
  (quotes,rawUnitPrices) => {
    return quotes.map(quote => {
      const domain = getDomain(quote.createdBy)
      const unitPrices = getUnitPricesForDomain(rawUnitPrices,domain)

      let total = 0
      quote.products.forEach(product => {
        const { quantity } = product
        const lineItems = getLineItems(product,unitPrices)
        const unitPrice = lineItems.reduce((acc,cur) => {
          return acc += Number(cur.unitPrice.price) * Number(cur.quantity)
        },0)
        total += Number(quantity || 0) * Number(unitPrice || 0)
      })
      const transport = calculateTransport(total,quote.products)
      total += transport
      return { ...quote,total,numProducts: quote.products.length }
    })
  }
)

export const selectSearchedForQuotes = createSelector(
  [selectQuotesWithTotals,selectSearchTerm],
  (quotes,searchTerm) => {
    return quotes.filter(q => !searchTerm || 
      q.createdBy.includes(searchTerm) || 
      q.id.includes(searchTerm) ||
      q.status.includes(searchTerm) ||
      (q.clientReference && q.clientReference.includes(searchTerm)) ||
      formatDatetime(q.createdAt).includes(searchTerm) ||
      formatCurrency(q.total).includes(searchTerm)
    )
  }
)

export const selectSortedQuotes = createSelector(
  [selectSearchedForQuotes,selectSortBy,selectSortAsc],
  (quotes,sortBy,sortAsc) => {
    quotes = quotes.map(q => {
      if(!q.clientReference) q.clientReference = null
      return q
    })
    return sortArray([...quotes],{
      by: sortBy,
      order: sortAsc ? `asc` : `desc`,
      computed: { createdAt: row => row.createdAt.seconds },
    })
  }
)

export const selectQuotesPage = createSelector(
  [selectSortedQuotes,selectPage,selectPerPage],
  (quotes,page,perPage) => {
    return quotes.slice(page * perPage,(page + 1) * perPage)
  }
)