import { createAsyncThunk,createSlice } from '@reduxjs/toolkit'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { parseCollectionSnapshot } from '../../utils/firebaseUtils'

const name = `orders`
let unsubscribe

export const subscribeToOrders = createAsyncThunk(`${name}/subscribe`,async(_,{ getState,dispatch }) => {
  if(unsubscribe) unsubscribe()
  unsubscribe = firebase.firestore().collection(name).onSnapshot(snapshot => {
    dispatch(setOrders(parseCollectionSnapshot(snapshot)))
  })
},{ condition: () => !!firebase.auth().currentUser })

const slice = createSlice({
  name,
  initialState: {
    status: `idle`,
    data: [],
    error: null,
  },
  reducers: {
    setOrders(state,action){
      state.data = action.payload
    },
  },
})

export default slice.reducer
export const { setOrders } = slice.actions
export const selectOrders = state => state[name].data

