import React from 'react'
import styled from 'styled-components'
import { color1 } from '../utils/colors'

const Footer = () => {
  return (
    <Container>
      <Vertical>
        <Link href="https://vandaglas.com/imprint/" text="Imprint"/>
        <Link href="https://vandaglas.com/privacy-statement/" text="Privacy"/>
        <Link href="https://vandaglas.com/disclaimer/" text="Disclaimer"/>
        <Link href="https://vandaglas.com/vandaglas-b-v-general-terms-sales/" text="GTS"/>
        <Link href="https://vandaglas.com" text="Vandaglas.com"/>
      </Vertical>
      <Img alt="Vandaglas" src={`/vandaglas-white-transparent.png`}/>
      <SocialMedia>
      </SocialMedia>
    </Container>
  )
}

const Link = ({ text,href }) => {
  return (
    <StyledLink target="_blank" rel="noopenere noreferrer" href={href}>{text.toLowerCase()}</StyledLink>
  )
}

export default Footer 

const Container = styled.div`
  flex:0;
  display:flex;
  background-color:${color1};
  color:white;
  border-radius:0;
  padding:0;
  position:relative;
`
const StyledLink = styled.a`
  font-size:1.1rem;
  text-decoration:none;
  color:#FFFFFF80;
  margin:2px;
`
const Img = styled.img`
  margin:15px 0px;
  max-width:15vw;
  @media (max-width: 600px) {
    max-width:100px;
  }
`
const Vertical = styled.div`
  margin:1vh 3vh;
  flex-direction:column;
  align-items:flex-start;
  flex-grow:1;
`
const SocialMedia = styled.div`
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:center;
  gap:10px;
  margin:1vh 3vh;
`

