import React from 'react'
import styled from 'styled-components'

const MarkdownEditor = ({ markdown,onChange,onBlur }) => {
  return (
    <Container>
      <TextArea
        value={markdown}
        rows={6}
        onChange={e => onChange(e.target.value)}
        onBlur={onBlur}
        type="text"/>
    </Container>
  )
}

export default MarkdownEditor 

const Container = styled.div`
  display:flex;
  margin:20px;
`
const TextArea = styled.textarea`
  flex-grow:1;
`

