import React from 'react'
import MUICarousel from 'react-material-ui-carousel'
import { Paper } from '@material-ui/core'
import styled from 'styled-components'

export default function Carousel(props)
{
  var items = [
    {
      name: ``,
      description: ``,
      img: `/carousel/1.jpg`,
    },
    {
      name: ``,
      description: ``,
      img: `/carousel/2.png`,
    },
    // {
    //   name: ``,
    //   description: ``,
    //   img: `/carousel/3.png`,
    // },
    {
      name: ``,
      description: ``,
      img: `/carousel/4.jpg`,
    },
  ]

  return (
    <Container>
      <MUICarousel 
        navButtonsAlwaysInvisible
        animation="slide">
        {
          items.map((item,i) => <Item key={i} item={item} />)
        }
      </MUICarousel>
    </Container>
  )
}

const Item = ({ item }) => {
  return (
    <StyledPaper>
      <h2>{item.name}</h2>
      <p>{item.description}</p>
      <Img alt="vandaglas" src={item.img}/>
    </StyledPaper>
  )
}

const Container = styled.div`
  /* padding:3vw; */
  /* padding-top:0; */
  box-sizing:border-box;
  div{
    width:100%;
    flex-grow:0;
    display:block;
  }
  .MuiPaper-root {
    box-shadow:unset;
    margin-top:0;
  }
`
const Img = styled.img`
  align-self:center;
  /* max-width:90vw; */
  /* max-height:40vh; */
  height:30vh;
  width:30vh;
  object-fit:contain;


  /* width:600px; */
  
`
const StyledPaper = styled(Paper)`
  /* background-color:lightgray !important; */
  background-color:transparent;
  display:flex !important;
  align-items:center;
  justify-content:center;
  box-shadow:unset;
  flex:1;
  padding:0;
  border-radius:0px !important;
  border:none;
  box-sizing:border-box;
  flex-direction:column;
`