import React from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import UsersTable from './usersTable'

const Users = () => {
  return (
    <MainLayout>
      <Container>
        <UsersTable/>
      </Container>
    </MainLayout>
  )
}

export default Users 

const Container = styled.div`
  flex-direction:column;
  box-sizing:border-box;
  padding:30px;
`

