import React from 'react'
import styled from 'styled-components'
import ButtonGroup from './buttonGroup'
import { selectUnitPrices } from '../redux/slices/unitPrices.slice'
import { useDispatch,useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { selectQuote,updateProduct } from '../redux/slices/quote.slice'

const ThicknessOption = () => {
  const { productId } = useParams()
  const quote = useSelector(selectQuote)
  const product = quote.products[Number(productId)] || {}
  const dispatch = useDispatch()

  const handleChange = value => {
    dispatch(updateProduct({ productId,thickness: value }))
  }

  const unitPrices = useSelector(selectUnitPrices)
  const { rawMaterial } = product || {}
  const thicknesses = Array.from(new Set(
    unitPrices
      .filter(u => u.rawMaterial === rawMaterial)
      .map(u => Number(u.thickness))
  ))

  const options = thicknesses.sort().map(num => ({
    value: num,
    label: `${num}mm`,
  }))

  return (
    <Container>
      {!!options.length && <Label>Stärke:</Label>}
      <ButtonGroup
        style={{ flexDirection: `row`,flexWrap: `wrap`,margin: 0,width: `auto` }}
        onChange={handleChange}
        value={product.thickness}
        options={options}/>
    </Container>
  )
}

export default ThicknessOption 

const Container = styled.div`
  display:flex;
  flex:0;
  padding:0;

`
const Label = styled.h2`
  color:white;
  font-weight:400;
  font-size:1rem;
  margin:0;
`