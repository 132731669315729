import React from 'react'
import styled from 'styled-components'
import 'firebase/auth'
import { Link } from 'react-router-dom'
import { color1,color2 } from '../utils/colors'

const NotAuthorised = () => {
  return (
    <Container>
      <Vertical>
        <Box>
          <Title>Nicht zugelassen</Title>
          <Subtitle>Sie benötigen eine Genehmigung, um diese Seite zu sehen. Sprechen Sie mit unserem Kundenservice um sie zu beantragen.</Subtitle>
          <Link to="/"><Button>{`< Back home`}</Button></Link>
        </Box>
      </Vertical>
    </Container>
  )
}

export default NotAuthorised 

const Container = styled.div`
  height:80vh;
`
const Vertical = styled.div`
  flex-direction:column;
`
const Title = styled.h2`
  margin:15px 0;
  font-size:2.5rem;
  color:white;
  font-weight:400;
`
const Box = styled.div`
  padding:80px 20px 40px 20px;
  width:auto;
  min-width:90vw;
  box-sizing:border-box;
  flex-direction:column;
  gap:20px;
  background-image: linear-gradient(45deg,${color1},${color2});
`
const Subtitle = styled.p`
  font-size:1rem;
  color:white;
`
const Button = styled.button`
  width:100%;
  border:none;
  color:white;
  background-color:black;
  box-sizing:border-box;
  padding:10px;
  cursor:pointer;
`