import React from 'react'
import { Eye } from 'react-feather'
import { useHistory } from 'react-router-dom'
import IconButton from './iconButton'

const EditQuote = ({ quote }) => {
  const history = useHistory()
  const handleClick = () => {
    history.push(`/quotes/${quote.id}`)
  }
  return (
    <IconButton
      Icon={Eye}
      hoverText="Angebot ansehen"
      onClick={handleClick}/>
  )
}

export default EditQuote 

