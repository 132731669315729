import React,{ useRef,useState } from 'react'
import styled from 'styled-components'
import firebase from 'firebase/app'
import 'firebase/auth'
import { toast } from 'react-hot-toast'
import { errorStyle,successStyle } from '../utils/toast.format'
import { Link,useHistory } from 'react-router-dom'
import { color1,color2,highlight } from '../utils/colors'
import { useFB } from '../hooks/useFirebase'

const SignUp = () => {
  const [state,setState] = useState({
    email: ``,
    password: ``,
    firstname: ``,
    lastname: ``,
    company: ``,
    address1: ``,
    address2: ``,
    address3: ``,
  })
  const ref = useRef()
  const db = useFB()
  const history = useHistory()

  const handleSubmit = async() => {
    if(Object.keys(state).filter(key => key !== `address3`).some(key => !state[key]))
      return toast(`Please fill out all fields`,errorStyle)
    try{
      const { email,password,...rest } = state
      await firebase.auth().createUserWithEmailAndPassword(email,password)
      await db.doc(`users/${email}`).set({ ...rest,email },{ merge: true })
      toast(`Ein Konto wurde für Sie erstellt. Bitte loggen Sie sich ein.`,successStyle)
      history.push(`/login`)
    }catch(err){
      const { message } = err
      if(message) toast(message,errorStyle)
    }
  }

  const handleKeyUp = e => {
    if(e.key === `Enter`) {
      e.target.blur()
      ref.current.click()
    }
  }

  return (
    <Container>
      <Vertical>
        <Box>
          <Img src={`/vandaglas-black-transparent.png`}/>
          <Title>Konto erstellen</Title>
          <Input
            placeholder="Vorname"
            value={state.firstname}
            onChange={e => setState(s => ({ ...s,firstname: e.target.value.trim() }))}/>
          <Input
            placeholder="Nachname"
            value={state.lastname}
            onChange={e => setState(s => ({ ...s,lastname: e.target.value.trim() }))}/>
          <Email
            placeholder="Email"
            value={state.email}
            onChange={e => setState(s => ({ ...s,email: e.target.value.toLowerCase().trim() }))}/>
          <Password
            placeholder="Password"
            value={state.password}
            type="password"
            onChange={e => setState(s => ({ ...s,password: e.target.value }))}/>
          <Input
            placeholder="Firmenname"
            value={state.company}
            onChange={e => setState(s => ({ ...s,company: e.target.value }))}/>
          <Input
            placeholder="Straße"
            value={state.address1}
            onChange={e => setState(s => ({ ...s,address1: e.target.value }))}/>
          <Input
            placeholder="PLZ und Ort"
            value={state.address2}
            onKeyUp={handleKeyUp}
            onChange={e => setState(s => ({ ...s,address2: e.target.value }))}/>
          <Input
            placeholder="Land"
            value={state.address3}
            onKeyUp={handleKeyUp}
            onChange={e => setState(s => ({ ...s,address3: e.target.value }))}/>
          <Input
            placeholder="Telefonnummer"
            value={state.phone}
            onKeyUp={handleKeyUp}
            onChange={e => setState(s => ({ ...s,phone: e.target.value }))}/>
          <Button ref={ref} onClick={handleSubmit}>Enter</Button>
          <Line/>
          <Options>
            <Link to="/login"><Option>{`< Zurück zum Login`}</Option></Link>
            <Link to="/"><Option>Home</Option></Link>
          </Options>
        </Box>
      </Vertical>
    </Container>
  )
}

export default SignUp 

const Container = styled.div`
  height:80vh;
`
const Vertical = styled.div`
  flex-direction:column;
`
const Title = styled.h2`
  margin:15px 0;
  font-size:2.5rem;
  color:white;
  font-weight:400;
  text-align:center;
`
const Box = styled.div`
  padding:80px 20px 40px 20px;
  width:auto;
  min-width:360px;
  max-width:90vw;
  box-sizing:border-box;
  flex-direction:column;
  gap:20px;
  background-image: linear-gradient(45deg,${color1},${color2});
`
const Input = styled.input`
  width:80%;
  padding:10px;
  box-sizing:border-box;
  background-color:white;
  border:none;
  border-radius:40px;
  &:focus{
    outline:none;
    box-shadow: 0 0 3pt 2pt ${highlight};
  }
`
const Email = styled(Input)`
`
const Password = styled(Input)`
`
const Button = styled.button`
  width:80%;
  border:none;
  color:white;
  background-color:black;
  box-sizing:border-box;
  padding:10px 0;
  cursor: pointer;
  border-radius:40px;
`
const Line = styled.div`
  margin-top:20px;
  border-bottom: 1px solid lightgray;
  width:100%;
`
const Options = styled.div`
  width:100%;
  flex-grow:1;
  flex-direction:row;
  justify-content:space-between;
`
const Option = styled.button`
  background:transparent;
  border:none;
  color:white;
  cursor:pointer;
`
const Img = styled.img`
  height:60px;
`