import React from 'react'
import { useFB } from '../hooks/useFirebase'
import { useUser } from '../hooks/useUser'
import Button from './button'
import { useHistory } from 'react-router-dom'
import { errorStyle,successStyle } from '../utils/toast.format'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { selectQuote } from '../redux/slices/quote.slice'

const SaveQuote = () => {
  const fb = useFB()
  const user = useUser()
  const history = useHistory()
  const quote = useSelector(selectQuote)

  const handleClick = async() => {
    const clientReference = window.prompt(`Möchten Sie eine Kundenreferenz eingeben?`)
    try{
      if(!user.email) return history.push(`/quotes`)
      const answer = await axios({
        method: `get`,
        url: `https://europe-west2-swallow-quotes.cloudfunctions.net/getNewQuoteId`,
      })
      const quoteRef = fb.collection(`quotes`).doc(String(answer.data.id))
      quoteRef.set({
        ...quote,
        createdBy: user.id,
        createdAt: new Date(),
        clientReference,
        status: `live`,
      })
      toast(`Angebot wurde unter Angebotsnummer ${quoteRef.id} gespeichert`,successStyle)
      history.push(`/quotes/${quoteRef.id}`)
    }catch(err){
      console.log(err)
      toast(`Sorry, wir konnten das Angebot nicht speichern. Bitte versuchen Sie es erneut.`,errorStyle)
    }
  }
  return (
    <Button
      text="Angebot speichern"
      onClick={handleClick}/>
  )
}

export default SaveQuote 

