import React,{ useRef } from 'react'
import styled from 'styled-components'
import { useFB } from '../hooks/useFirebase'
import { toast } from 'react-hot-toast'
import { errorStyle,successStyle } from '../utils/toast.format'
import Button from './button'
import { useSelector } from 'react-redux'
import { selectRawUnitPrices } from '../redux/slices/unitPrices.slice'

function chunk(arr,len) {
  var chunks = [],
    i = 0,
    n = arr.length

  while (i < n) 
    chunks.push(arr.slice(i,i += len))
  return chunks
}

const CsvReader = () => {
  const db = useFB()
  const ref = useRef()
  const unitPrices = useSelector(selectRawUnitPrices)

  const handleChange = async e => {
    const [file] = document.getElementById(`csvupload`).files
    try{
      if(file){
        const reader = new FileReader()
        reader.readAsText(file,`UTF-8`)
        reader.onerror = e => console.error(e)
        reader.onload = async e => {
          const objects = parseString(e.target.result)
          await deleteUnitPrices()
          await upsertObjects(objects)
        }
        toast(`Stückpreise erfolgreich aktualisiert`,successStyle)
      }
    }catch(err){
      console.error(err)
      toast(err.message,errorStyle)
    }

    const deleteUnitPrices = async() => {


      const chunks = chunk(unitPrices,500)
      await Promise.all(chunks.map(async chunk => {
        const batch = db.batch()
        chunk.forEach(u => {
          batch.delete(db.doc(`unitPrices/${u.id}`))
        })
        return batch.commit()
      }))
    }

    const parseString = string => {
      const lines = string.split(`\n`)
        .map(line => line.split(`,`))
      return lines.slice(1).map(line => {
        return lines[0].reduce((acc,cur,index) => {
          acc[cur.trim()] = line[index].replace(/,/g,``).replace(/€/g,``)
          return acc
        },{})
      })
    }

    const upsertObjects = async objects => {
      const chunks = chunk(objects,500)
      await Promise.all(chunks.map(async chunk => {
        await db.runTransaction(async t => {
          chunk.forEach(object => {
            t.set(db.doc(`unitPrices/${object.productNumber}`),object,{ merge: true })
          })
        })
      }))
    }

  }

  const handleClick = () => {
    const password = window.prompt(`Passwort bitte`)
    if(!password) return
    if(password !== `safe_csv_upload`) return toast(`Das Password war nicht richtig`,errorStyle)
    var elem = document.getElementById(`csvupload`)
    if(elem && document.createEvent) {
      var event = document.createEvent(`MouseEvents`)
      event.initEvent(`click`,true,false)
      elem.dispatchEvent(event)
    }
    // ref.current.click()
  }

  return (
    <Container>
      <Button
        text="Upload CSV"
        onClick={handleClick}/>
      <input 
        ref={ref}
        id="csvupload"
        accept=".csv"
        onChange={handleChange}
        onClick={e => e.target.value = ``}
        type="file"/>
    </Container>
  )
}

export default CsvReader 

const Container = styled.div`
  input{
    display:none;
  }
`

