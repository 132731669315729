import styled from 'styled-components'
import ButtonGroup from './buttonGroup'
import OptionGroup from './optionGroup'
import React from 'react'
import { useParams } from 'react-router'
import { selectQuote,updateProduct } from '../redux/slices/quote.slice'
import { useDispatch,useSelector } from 'react-redux'

const ModelOption = () => {
  const { productId } = useParams()
  const quote = useSelector(selectQuote)
  const product = quote.products[Number(productId)] || {}
  const dispatch = useDispatch()

  const handleChange = value => {
    dispatch(updateProduct({ productId,model: value }))
    if(value === `round`)
      dispatch(updateProduct({ productId,height: product.width }))
  }

  const options = [
    {
      value: `square`,
      label: `Rechteckig`,
    },
    {
      value: `round`,
      label: `Rund`,
    },
    {
      value: `other`,
      label: `Andere Form`,
    },
  ]


  return (
    <Container>
      <OptionGroup label="Form">
        <ButtonGroup
          value={product.model}
          options={options}
          editable
          onChange={handleChange}/>
      </OptionGroup>
    </Container>
  )
}

export default ModelOption 

const Container = styled.div`
  max-width:300px;
`

