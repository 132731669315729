import React from 'react'
import styled from 'styled-components'
import CsvReader from './csvReader'
import UnitPricesTable from './unitPricesTable'
import DomainSelector from './domainSelector'
import MainLayout from './mainLayout'
import ProductFilter from './productFilter'
import ThicknessFilter from './thicknessFilter'
import HeightFilter from './heightFilter'
import WidthFilter from './widthFilter'
import { useSelector } from 'react-redux'
import Spinner from './spinner'
import { selectUnitPrices } from '../redux/slices/unitPrices.slice'

const UnitPrices = () => {
  const unitPrices = useSelector(selectUnitPrices)
  return (
    <MainLayout>
      {(!unitPrices || !unitPrices.length) && 
      <Spinner/>
      }
      {unitPrices && unitPrices.length && 
      <Container>
        <CsvReader/>
        <Hr/>
        <ProductFilter/>
        <Hr/>
        <ThicknessFilter/>
        <Hr/>
        <HeightFilter/>
        <Hr/>
        <WidthFilter/>
        <Hr/>
        <DomainSelector/>
        <Hr/>
        <UnitPricesTable/>
      </Container>
      }
    </MainLayout>
  )
}

export default UnitPrices 

const Container = styled.div`
  display:flex;
  flex-direction:column;
  gap:5px;
  box-sizing:border-box;
  padding:30px;
`
const Hr = styled.hr`
  width:100%;
  margin:0;
`

