import React from 'react'
import styled from 'styled-components'

const ErrorBoundaryMessage = () => {
  return (
    <Container>
      There has been an error, please contact support. Thanks
    </Container>
  )
}

export default ErrorBoundaryMessage 

const Container = styled.div`
  display:flex;
`

