import React from 'react'
import styled from 'styled-components'
import { useFB } from '../hooks/useFirebase'
import { useUser } from '../hooks/useUser'
import Button from './button'

const AddTeam = () => {
  const fb = useFB()
  const user = useUser()
  const handleClick = () => {
    fb.collection(`teams`).doc().set({
      name: `New Team`,
      createdBy: user.id,
      createdAt: new Date(),
    })
  }
  return (
    <Container>
      <Button 
        text="Add Team"
        onClick={handleClick}/>
    </Container>
  )
}

export default AddTeam 

const Container = styled.div`
  display:flex;
  flex-direction:column;
`
