import React from 'react'
import styled from 'styled-components'
import CreateQuote from './createQuote'
import MainLayout from './mainLayout'
import Modal from './modal'
import QuotesTable from './quotesTable'

const Quotes = () => {
  return (
    <MainLayout>
      <Container>
        <Title>Angebote</Title>
        <CreateQuote/>
        <QuotesTable/>
        <Modal/>
      </Container>
    </MainLayout>
  )
}

export default Quotes 

const Container = styled.div`
  flex-direction:column;
  box-sizing:border-box;
  padding:30px;
  overflow-x:hidden;
`
const Title = styled.h1`
  margin-top:40px;
`

