import { createSelector } from '@reduxjs/toolkit'
import { selectUnitPrices } from '../slices/unitPrices.slice'

export const selectHoleTypes = createSelector(
  [selectUnitPrices],
  unitPrices => {
    return Array.from(new Set(
      unitPrices
        .map(u => u.holeType)
        .filter(Boolean),
    ))
  },
)