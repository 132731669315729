import { createAsyncThunk,createSelector,createSlice } from '@reduxjs/toolkit'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { parseCollectionSnapshot } from '../../utils/firebaseUtils'
import { selectQuote } from './quote.slice'
import { getDomain } from '../../utils/getDomain'

const name = `unitPrices`
let unsubscribe

export const subscribeToUnitPrices = createAsyncThunk(`${name}/subscribe`,async(_,{ getState,dispatch }) => {
  if(unsubscribe) unsubscribe()
  unsubscribe = firebase.firestore().collection(name).onSnapshot(snapshot => {
    dispatch(setUnitPrices(parseCollectionSnapshot(snapshot)))
  })
},{ condition: () => !!firebase.auth().currentUser })

const slice = createSlice({
  name,
  initialState: {
    status: `idle`,
    data: [],
    error: null,
  },
  reducers: {
    setUnitPrices(state,action){
      state.data = action.payload
    },
  },
})

export default slice.reducer
export const { setUnitPrices } = slice.actions
export const selectRawUnitPrices = state => state[name].data

export const selectUnitPrices = createSelector(
  [selectRawUnitPrices,selectQuote],
  (rawUnitPrices,quote) => {
    return getUnitPricesForDomain(rawUnitPrices,getDomain(quote.createdBy))
  },
)

export const selectDomains = createSelector(
  [selectRawUnitPrices],
  unitPrices => {
    if(!unitPrices || !unitPrices.length) return []
    const domains = Object
      .keys(unitPrices[0])
      .filter(k => k.includes(`.`))
      .sort()
    return domains 
  },
)

export const getUnitPricesForDomain = (unitPrices,domain) => {
  unitPrices = unitPrices.map(unitPrice => {
    return {
      ...unitPrice,
      price: domain 
        ? unitPrice[domain] || unitPrice.standardPrice 
        : unitPrice.standardPrice,
    }
  })
  return unitPrices
}
