import React from 'react'
import { X } from 'react-feather'
import { toast } from 'react-hot-toast'
import { successStyle } from '../utils/toast.format'
import IconButton from './iconButton'
import { useDispatch } from 'react-redux'
import { deleteProduct } from '../redux/slices/quote.slice'

const DeleteProduct = ({ product,index }) => {
  const dispatch = useDispatch()
  const handleClick = async() => {
    if(window.confirm(`Möchten Sie dieses Produkt wirklich löschen?`)){
      dispatch(deleteProduct(index))
      toast(`Produkt wurde gelöscht`,successStyle)
    }
  }
  return (
    <IconButton
      Icon={X}
      hoverText="Produkt löschen"
      onClick={handleClick}/>
  )
}

export default DeleteProduct 


