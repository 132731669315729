import React from 'react'
import styled from 'styled-components'
import Navigation from './navigation'
import Footer from './footer'

const MainLayout = ({ children }) => {
  return (
    <Container>
      <Navigation/>
      <Content>
        {children}
      </Content>
      <Footer/>
    </Container>
  )
}

export default MainLayout 

const Container = styled.div`
  flex-direction:column;
  min-height:100vh;
  overflow-x:hidden;
`
const Content = styled.div`
  flex:1;
  box-sizing:content-box;
  margin-bottom:150px;
  overflow-x:hidden;
  justify-content:flex-start;
  align-items:flex-start;
`

