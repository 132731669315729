import React from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { selectQuotes } from '../redux/slices/quotes.slice'
import MainLayout from './mainLayout'
import { selectOrders } from '../redux/slices/orders.slice'
import ProductsTable from './productsTable'
import PdfDownload from './pdfDownload'
import moment from 'moment'
import { useUser } from '../hooks/useUser'
import ClientReference from './clientReference'
import { useHistory } from 'react-router-dom'
import Button from './button'
import { selectQuote,setQuote } from '../redux/slices/quote.slice'
import { selectUsers } from '../redux/slices/users.slice'
import SaveQuote from './saveQuote'
import DuplicateQuote from './duplicateQuote'

export const QuoteContext = React.createContext()

const Quote = () => {
  const dispatch = useDispatch()
  const { quoteId } = useParams()
  const quotes = useSelector(selectQuotes)
  const history = useHistory()
  const quote = useSelector(selectQuote)
  const orders = useSelector(selectOrders)
  const order = orders.find(o => o.quoteId === quoteId)
  const editable = !order
  const user = useUser()
  const users = useSelector(selectUsers)
  const creator = users.find(u => u.email === quote.createdBy) || user || {}
  const { 
    firstname,lastname,company,address1,address2,address3,phone,email,
  } = creator

  if(quote.id !== quoteId){
    const foundQuote = quotes.find(q => q.id === quoteId)
    if(foundQuote) dispatch(setQuote(foundQuote)) 
  }
  
  // eslint-disable-next-line prefer-destructuring
  const domain = (creator.email || ``).split(`@`)[1]
  const imageFilename = {
    'gc-gruppe.de': `gc.png`,
    'richter-frenzel.de': `rf.png`,
    'pum.de': `pm.png`,
    'wakeflow.io': `wakeflow.png`,
    'glassolutionsbv.nl': `vandaglas.png`,
    'vandaglas.com': `vandaglas.png`,
  }[domain]

  return (
    <MainLayout>
      <QuoteContext.Provider value={{ ...quote,editable }}>
        <Container>
          <QuotePdf id="quote-pdf">
            <Title>{`Vandaglas Angebot`}</Title>
            <Addresses>
              <AddressLeft>
                <Logo src={`/logos/vandaglas.png`}/>
                <Line style={{ fontWeight: `bold` }}>Vandaglas</Line>
                <Line>Gevaren 11</Line>
                <Line style={{ marginBottom: 20 }}>6071BP&nbsp;Swalmen</Line>
                <Line><strong>Tel</strong>&nbsp;&nbsp;0475 50 31 31 </Line>
                <Line style={{ marginBottom: 20 }}><strong>Email</strong>&nbsp; swallow@vandaglas.com</Line>
                <Line><strong>Kamer van Koophandel</strong>&nbsp; 30129925</Line>
                <Line><strong>BTW Nummer</strong>&nbsp; NL0070,46,3756,B.46</Line>
              </AddressLeft>
              <AddressRight onClick={() => history.push(`/account`)}>
                {imageFilename && <Logo src={`/logos/${imageFilename}`}/>}
                {(firstname || lastname) && <Line>{`${firstname} ${lastname}`}</Line>}
                {company && <Line style={{ fontWeight: `bold` }}>{company}</Line>}
                {address1 && <Line>{address1}</Line>}
                {address2 && <Line>{address2}</Line>}
                {address3 && <Line>{address3}</Line>}
                {email && <Line style={{ marginTop: 20 }}><strong>Email</strong>&nbsp; {`${email}`}</Line>}
                {phone && <Line><strong>Tel</strong>&nbsp; {`${phone}`}</Line>}
                {quote && quote.id && <Line><strong>Kundenreferenz</strong><ClientReference/></Line>}
              </AddressRight>
            </Addresses>
            <RightAlign><strong>Datum</strong>&nbsp;{`${moment().format(`DD MMM YYYY`)}`}</RightAlign>
            {quote && quote.id && <RightAlign><strong>Angebotsnummer:</strong>&nbsp;{quote.id}</RightAlign>}
            <ProductsTable quoteId={quoteId}/>
            <AGB>Angebote sind 2 Monate von Ausstellungsdatum gültig.</AGB>
            <AGB>Es gelten die Allgemeinen Geschäftsbedingungen. Sie folgen mit den Auftragsdokumenten.</AGB>
          </QuotePdf>
          <Buttons>
            <Button text="Alle Angebote" onClick={() => history.push(`/quotes`)}/>
            {quote && quote.status && <DuplicateQuote quote={quote} large/>}
            {quote && quote.status && <PdfDownload elementId={`quote-pdf`} filename={`Vandaglas_Quote_${quote.id}.pdf`}/>}
            {quote && !quote.status && <SaveQuote/>}
          </Buttons>
        </Container>
      </QuoteContext.Provider>
    </MainLayout>
  )
}

export default Quote 

const Container = styled.div`
  flex-direction:column;
  box-sizing:border-box;
  padding:30px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const Title = styled.h1`
`
const Logo = styled.img`
  max-height:40px;
  /* margin-left:10px; */
  padding:10px;
`
const QuotePdf = styled.div`
  box-sizing:content-box;
  padding:20px;
  max-width:1000px;
  flex-direction:column;
  justify-content:flex-start;

`
const Addresses = styled.div`
  width:100%;
  gap:20px;
  align-items:flex-start;
  @media (max-width: 600px) {
    flex-wrap:wrap;
    display:flex;
  }
`
const Address = styled.div`
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
`
const AddressLeft = styled(Address)`
`
const AddressRight = styled(Address)`
  cursor:pointer;
`
const Buttons = styled.div`
  flex-wrap:wrap;
`
const Line = styled.div`
  text-align:left;
  align-self:flex-start;
  justify-self:flex-start;
  width:auto;
  margin-left:10px;
`
const RightAlign = styled.div`
  text-align:right;
  margin:5px;
  align-self:flex-end;
  justify-self:flex-end;
  width:auto;
`
const AGB = styled.div`
  margin:20px;
`



