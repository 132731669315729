import React,{ useEffect,useRef,useState } from 'react'
import styled from 'styled-components'
import { useFB } from '../hooks/useFirebase'

const TeamName = ({ team }) => {
  const [value,setValue] = useState(team.name)
  const fb = useFB()
  const ref = useRef()

  const handleKeyUp = e => {
    const { key } = e
    if(key === `Enter`) submit()
  }

  const submit = () => {
    fb.doc(`teams/${team.id}`).update({ name: value })
    ref.current.blur()
  }

  useEffect(() => setValue(team.name),[team])

  return (
    <Container>
      <Input
        ref={ref}
        value={value}
        onKeyUp={handleKeyUp}
        onBlur={submit}
        onChange={e => setValue(e.target.value)}/>
    </Container>
  )
}

export default TeamName 

const Container = styled.div`
  display:flex;
  flex: 1;
`
const Input = styled.input`
  background-color:transparent;
  font-size:1.2rem;
`

