import React,{ useRef,useState } from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import { useFB } from '../hooks/useFirebase'
import { useUser } from '../hooks/useUser'
import toast from 'react-hot-toast'
import { successStyle } from '../utils/toast.format'
import { useHistory } from 'react-router'

const Account = () => {
  const ref = useRef()
  const db = useFB()
  const user = useUser()
  const history = useHistory()

  const {
    firstname,lastname,company,address1,address2,address3,phone,
  } = user
  const [state,setState] = useState({
    firstname,
    lastname,
    company,
    address1,
    address2,
    address3,
    phone,
  })

  const handleSubmit = async() => {
    const updates = {};
    [`firstname`,`lastname`,`company`,`address1`,`address2`,`address3`,`phone`].forEach(key => {
      if(state[key]) updates[key] = state[key]
    })
    await db.doc(`users/${user.id}`).update(updates)
    toast(`Ihre Daten wurden gespeichert`,successStyle)
    history.push(`/quotes`)
  }

  const handleKeyUp = e => {
    if(e.key === `Enter`) {
      e.target.blur()
      ref.current.click()
    }
  }

  return (
    <MainLayout>
      <Container>
        <Title>Mein Konto</Title>
        <Input
          placeholder="Vorname"
          value={state.firstname}
          onChange={e => setState(s => ({ ...s,firstname: e.target.value }))}/>
        <Input
          placeholder="Nachname"
          value={state.lastname}
          onChange={e => setState(s => ({ ...s,lastname: e.target.value }))}/>
        <Input
          placeholder="Firmenname"
          value={state.company}
          onChange={e => setState(s => ({ ...s,company: e.target.value }))}/>
        <Input
          placeholder="Straße"
          value={state.address1}
          onChange={e => setState(s => ({ ...s,address1: e.target.value }))}/>
        <Input
          placeholder="PLZ und Ort"
          value={state.address2}
          onKeyUp={handleKeyUp}
          onChange={e => setState(s => ({ ...s,address2: e.target.value }))}/>
        <Input
          placeholder="Land"
          value={state.address3}
          onKeyUp={handleKeyUp}
          onChange={e => setState(s => ({ ...s,address3: e.target.value }))}/>
        <Input
          placeholder="Telefonnummer"
          value={state.phone}
          onKeyUp={handleKeyUp}
          onChange={e => setState(s => ({ ...s,phone: e.target.value }))}/>
        <Button ref={ref} onClick={handleSubmit}>Speichern</Button>
      </Container>
    </MainLayout>
  )
}

export default Account 

const Container = styled.div`
  flex-direction:column;
  box-sizing:border-box;
  padding:30px;
`
const Title = styled.h1`
  margin-top:40px;
`
const Input = styled.input`
  width:80%;
  padding:10px;
  box-sizing:border-box;
  background-color:white;
  border:thin solid gray;
  border-radius:40px;
  margin:5px;
`
const Button = styled.button`
  width:80%;
  border:none;
  color:white;
  background-color:black;
  box-sizing:border-box;
  padding:10px 0;
  cursor: pointer;
  border-radius:40px;
  margin:5px;
`