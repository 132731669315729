import React from 'react'
import Button from './button'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

const PdfDownload = ({ elementId,filename }) => {

  const handleClick = () => {
    Array.from(document.getElementsByClassName(`disappear-in-pdf`)).forEach(elem => elem.style.display = `none`)
    Array.from(document.getElementsByClassName(`client-reference`)).forEach(elem => elem.style.border = `none`)
    const elem = document.getElementById(elementId)
    const divHeight = elem.clientHeight
    const divWidth = elem.clientWidth
    const ratio = divHeight / divWidth
    const options = { scrollY: -window.scrollY }
    html2canvas(elem,options).then(canvas => {
      const divImage = canvas.toDataURL(`image/png`)
      // saveAs(divImage,`one.png`)
      const pdfWidth = 210
      const pdfHeight = 297

      var pdf = new jsPDF({
        orientation: `portait`,
        unit: `mm`,
        format: [pdfWidth,pdfHeight],
      })
      const width = pdf.internal.pageSize.getWidth()
      let height = pdf.internal.pageSize.getHeight()
      height = ratio * width
      pdf.addImage(divImage,`PNG`,0,0,width,height)
      pdf.save(filename)
    })
    Array.from(document.getElementsByClassName(`disappear-in-pdf`)).forEach(elem => elem.style.display = `flex`)
    Array.from(document.getElementsByClassName(`client-reference`)).forEach(elem => elem.style.border = `thin solid gray`)
  }

  // function saveAs(uri,filename) {
  //   var link = document.createElement(`a`)
  //   if (typeof link.download === `string`) {
  //     link.href = uri
  //     link.download = filename
  //     //Firefox requires the link to be in the body
  //     document.body.appendChild(link)
  //     //simulate click
  //     link.click()
  //     //remove the link when done
  //     document.body.removeChild(link)
  //   } else 
  //     window.open(uri)
  // }

  return (
    <Button
      text="PDF runterladen"
      onClick={handleClick}/>
  )
}

export default PdfDownload 


